import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare function callMenuFunction(): any;
import { environment } from '@env/environment';
import { ApiService } from '@app/core';
import { Utils } from '@app/core/utility/Utils';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { GlobalConfig } from '@env/globalconfig';
import {NavService} from '@app/core/services/profile.service';


import { ActivatedRoute, Params,NavigationEnd } from '@angular/router';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap'

declare function readyFunction(type): any;
declare var $: any;


@Component({
  selector: 'app-private-header',
  templateUrl: './private-header.component.html',
  //styleUrls: ['./private-header.component.scss']
})
export class PrivateHeaderComponent implements OnInit {
  name: any;
  email: any;
  role: any;
  isHomePage: boolean;
  isShowAutoComplete: boolean;
  isUserLogin: boolean;
  isStudent: boolean;
  searchQuery: string = "";
  image: string = "";
  searchData: any = [];
  public lottieConfig: Object;
  public lottieConfigmock: Object;
  public lottieConfigbook: Object;
  public lottieConfigpaper: Object;
  public lottieConfigsolution: Object;
  bannerTitle:string;
  subscription:any;
  is_welcome_note:boolean = false;

  constructor(
    private router: Router,
    private _apiService: ApiService,
    private _broadcastService: BroadcastService,private route : ActivatedRoute,private navService:NavService
  ) { 

    this.lottieConfig = {
      path: 'https://assets2.lottiefiles.com/packages/lf20_DMgKk1.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
  };

  this.lottieConfigmock = {
    path: 'https://assets10.lottiefiles.com/packages/lf20_v4t3yi.json',
    renderer: 'svg',
    autoplay: true,
    loop: true
    };

    this.lottieConfigbook = {
      path: 'https://assets3.lottiefiles.com/datafiles/5FFwoVNq8EcKMH4/data.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
      };
      this.lottieConfigpaper = {
        path: 'https://assets2.lottiefiles.com/packages/lf20_qkyby4lm.json',
        renderer: 'svg',
        autoplay: true,
        loop: true
        };
        this.lottieConfigsolution = {
          path: 'https://assets10.lottiefiles.com/packages/lf20_szrbrL.json',
          renderer: 'svg',
          autoplay: true,
          loop: true
          };   


          this.router.events.subscribe(event => {

            if(event instanceof NavigationEnd) {
              const rt = this.getChild(this.route);  
              rt.data.subscribe(data => {  
              
      
                if(data.title==undefined){
      
                  this.bannerTitle = "";
        
                }else{
        
                  this.bannerTitle = data.title;
                  
        
                }
               
            })
          }
     
        });
       

  }


  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    var url = this.router.url;
    localStorage.setItem('url', environment.apiUrl);
    if (localStorage.getItem('accesstoken') == null) {
      this.isUserLogin = false;
      this.isStudent = false;

    } else {
      this.isUserLogin = true;
      this.isStudent = true;
    }

    if (url == "/" || url == "/home")
      this.isHomePage = true;
    else {
      this.isHomePage = false;
    }
    this.name = localStorage.getItem('username');
    this.email = localStorage.getItem('email');
    this.role = localStorage.getItem('role');
    this.image = localStorage.getItem('image');
    setTimeout(function () {
      readyFunction('menu');
    }, 100);

    $('body').addClass('bg-gray-200');
    $('body').removeClass('common-pages');


    this.subscription = this.navService.getNavChangeEmitter()
    .subscribe(item => this.checkImageAvailable());
    if (this.router.url.indexOf("/dashboard") > -1) {
      this.is_welcome_note = true;
    }else{
      this.is_welcome_note = false;
    }

  }
  public searchCourseDetails(searchQuery) {

   
    this.searchQuery = searchQuery;
    if (this.searchQuery.length > 3) {
      var data = {
        "item": this.searchQuery
      };
      let response = this._apiService.post(GlobalConfig.Urls.public_search, data);
      response.subscribe((val) => {
        if (val.status == '200') {
          this.searchData = val.data;
          if (val.data.length > 0) {
            this.isShowAutoComplete = true;
          } else {
            this.isShowAutoComplete = false;
          }

        }
      });
    } else {
      this.searchData = [];
      this.isShowAutoComplete = false;
    }
  }
  public logout() {
    this._broadcastService.broadcastLoader(true);
    this._apiService.get(GlobalConfig.Urls.login).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        localStorage.clear();
        window.location.href = "/";
        //this.router.navigateByUrl('/');
      }
    });
  }

  public checkImageAvailable() {
    this.image = localStorage.getItem('image');
    return Utils.avatarStatic(this.image);
  }

  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  } 

  ngAfterViewInit(){
    $(document).ready(function() {
    const iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
    const iconSidenav = document.getElementById('iconSidenav');
    const sidenav = document.getElementById('sidenav-main');
    var body = document.getElementsByTagName('body')[0];
    var className = 'g-sidenav-pinned';
    
    if (iconNavbarSidenav) {
      iconNavbarSidenav.addEventListener("click", function(){
        var body = document.getElementsByTagName('body')[0];
        var className = 'g-sidenav-pinned';
        const iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
        const iconSidenav = document.getElementById('iconSidenav');
        const sidenav = document.getElementById('sidenav-main');
        if (body.classList.contains(className)) {
          body.classList.remove(className);
          setTimeout(function() {
            sidenav.classList.remove('bg-white');
          }, 100);
          sidenav.classList.remove('bg-transparent');

          $( "#overlay" ).remove();
      
        } else {
             var overlay = $('<div id="overlay" > </div>');
             overlay.appendTo(document.body)
          body.classList.add(className);
          sidenav.classList.add('bg-white');
          sidenav.classList.remove('bg-transparent');
          iconSidenav.classList.remove('d-none');
        }
      });
    }
    
    if (iconSidenav) {
      iconSidenav.addEventListener("click", function(){
        var body = document.getElementsByTagName('body')[0];
        var className = 'g-sidenav-pinned';
        const iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
        const iconSidenav = document.getElementById('iconSidenav');
        const sidenav = document.getElementById('sidenav-main');
        console.log(iconNavbarSidenav);
        if (body.classList.contains(className)) {
          body.classList.remove(className);
          setTimeout(function() {
            sidenav.classList.remove('bg-white');
          }, 100);
          sidenav.classList.remove('bg-transparent');
          $( "#overlay" ).remove();
      
        } else {
          body.classList.add(className);
          sidenav.classList.add('bg-white');
          sidenav.classList.remove('bg-transparent');
          iconSidenav.classList.remove('d-none');
        }
      });
    }


  
    $( "body" ).on( 'click', '#overlay', function() {
      
      if (body.classList.contains(className)) {
        body.classList.remove(className);
        setTimeout(function() {
          sidenav.classList.remove('bg-white');
        }, 100);
        sidenav.classList.remove('bg-transparent');
        $( "#overlay" ).remove();
    
      }
    });
  });
  
  }
  searchList(data){

    let url = "/course-details-dashboard/preview/"+data._id;

    window.location.href=url;

  }

  searchTestList(data){

    let url = "/tests-dashboard/preview/"+data._id;

    window.location.href=url;

  }

}
