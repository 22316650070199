import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
declare function readyFunction(type): any;

@Component({
    selector: 'solved-question-paper-list',
    templateUrl: './solved-question-paper.component.html'
  })
export class SolvedQuestionPaperComponent implements OnInit {
    packageListData: any = [];
    star: any = [{ "no": 1, "fill": "no" }, { "no": 2, "fill": "no" }, { "no": 3, "fill": "no" }, { "no": 4, "fill": "no" }, { "no": 5, "fill": "no" }];

    price: string = "all";
    subject: string = "all";
    level: string = "all";
    course: string = "all";
    url: string = "";
    subjects: any = [];
    courses: any = [];
    levelList: any = [];
    priceList: any = [];
    isInitialLoading: boolean = false;
    isScrollLoading: boolean = false;
    selectedSubject: string = "All Subject";
    selectedCourse: string ="All Courses";
    selectedLavel: string ="All Level";
    isUserLogin: boolean;
    @Input()
    set UrlDetail(parentData: any) {
        this.url = parentData;
    }
    constructor(
      private _broadcastService: BroadcastService,
      private _apiService: ApiService,
      private router: Router,private activeRoute: ActivatedRoute
    ){
      
    }
    ngOnInit() {
      this.levelList = [
        { name: "+1", value: "plusone" },
        { name: "+2", value: "plustwo" },
        //{ name: "Degree", value: "degree" },
        //{ name: "Advanced", value: "advanced" },
      ];
      this.priceList = [
        { name: "Free", value: "free" },
        { name: "Paid", value: "paid" },
      ];
      if (localStorage.getItem('accesstoken') == null) {
        this.isUserLogin = false;  
      } else {
        this.isUserLogin = true;
      }


      this.activeRoute.queryParams.subscribe(queryParams => {
        const parmas = { ...queryParams.keys, ...queryParams };
  
        // if (parmas['free']) {
        //   this.price = parmas['free'];
        // }
        // else {
        //   this.price = "all";
        // }
        if (parmas['subject']) {
          this.subject = parmas['subject'];
          this.filterSubject(parmas['subject'])
         
        }
        else {
          this.subject = "all";
          this.filterSubject(parmas['subject']);
        }
        if (parmas['level']) {
          this.level = parmas['level'];
        }
        else {
          this.level = "all";
        }

        this.getSolvedquestions(1);
      });


     
      this._apiService.get(GlobalConfig.Urls.public_subject).subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        if (val.status == 200) {
          this.subjects = val.data;
          this.filterSubject(this.subject);
          this.filterLavel(this.level);
        }
      });
      this._apiService.get(GlobalConfig.Urls.public_courses).subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        if (val.status == 200) {
          this.courses = val.data
          console.log('course list',val.data);
        }
      });
      
    }
    public getSolvedquestions(page: number) {
      //this._broadcastService.broadcastLoader(true);
      this.isScrollLoading = true;      
      this.filterSubject(this.subject);
      this.filterLavel(this.level);
      console.log('page',page)
      this._apiService.get(this.url + "?free=" + this.price + "&subject=" + this.subject + "&level=" + this.level + "&course=" + this.course + "&pagin=true&page=" + page).subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        this.historyPush();
        this.isInitialLoading = true;
        this.isScrollLoading = false;
          if (val.status == 200) {
            if (page == 1) {
              //window.scrollTo(0, 0);
              this.packageListData = [];
            }
            this.pageNumber = page;
            if(val.data.solvedquestions.length){
              val.data.solvedquestions.forEach(element => {
                this.packageListData.push(element);
              });
            }else{
              this.packageListData = [];
            }
            setTimeout(function () {
              readyFunction('');
            }, 500);
          } else {
  
          }
          
  
      });
  
    }    
    public checkImageUrl(url) {
      return Utils.checkImageAvailable(url);
    }
    counter(i: number) {
      return new Array(i);
    }
    pageNumber: any = 1;
    onScroll() {
      if (!this.isScrollLoading) {
        if(this.packageListData.length>8){
          this.pageNumber = this.pageNumber + 1;
          this.getSolvedquestions(this.pageNumber);
        }
      } else {
        console.log('progress');
      }
    }
    public filterSubject(id) {   
      var name = "All Subject";
      this.subjects.forEach(element => {
        if (element.id == id) {
          name = element.name;
        }
      });
      this.selectedSubject = name;
     
    }  
    public filterCourse(id) {
      var name = "All Courses";
      this.courses.forEach(element => {
        if (element._id == id) {
          name = element.name;
        }
      });
      this.selectedCourse = name;
    }
    public filterLavel(id) {
      var name = "All Level";
      this.levelList.forEach(element => {
        if (element.value == id) {
          name = element.name;
        }
      });
      this.selectedLavel = name;
    }
    historyPush() {
      let locat = location.href;
      if (location.href.indexOf("?") > -1)
        locat = location.href.substring(0, location.href.indexOf("?"));
      let queryString = [];
      if (this.price) {
        queryString.push("free=" + this.price);
      }
      if (this.subject) {
        queryString.push("subject=" + this.subject);
      }
      if (this.level) {
        queryString.push("level=" + this.level);
      }
      if (this.course) {
        queryString.push("course=" + this.course);
      }
      if (queryString.length > 0) {
        locat = locat + "?" + queryString.join("&");
      }
      history.replaceState(null, null, location.origin);
      history.pushState(null, null, locat);
    }
}