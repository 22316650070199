import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { environment } from '@env/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
import { Title, Meta } from '@angular/platform-browser';
declare function callToolTip(): any;
@Component({
  selector: 'app-mock-test-detail',
  templateUrl: './mock-test.component.html',
  styleUrls: ['./mock-test.component.scss']
})

export class MockTestComponent implements OnInit {
  packageDetailListData: any = [];
  packageId: string = "";
  mode: any = 'en';
  star: any = [{ "no": 1, "fill": "no" }, { "no": 2, "fill": "no" }, { "no": 3, "fill": "no" }, { "no": 4, "fill": "no" }, { "no": 5, "fill": "no" }];

  @Input()
    set MockTestDetail(parentData: any) {
        this.packageDetailListData = parentData.packageDetailListData;  
        this.packageId = parentData.packageId;
    }
  constructor(
    private _apiService: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _broadcastService: BroadcastService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    
  }

  ngOnInit() {    
    this._broadcastService.broadcastLoader(true);
    callToolTip();
    if (this.packageDetailListData.userReviews) {
      var val = Math.round(parseFloat(this.toFixed(this.packageDetailListData.userReviews.averageRating)));
      this.star.forEach(element => {
        if (element.no <= val)
          element.fill = "fill-color";
      });
    }
    this._broadcastService.broadcastLoader(false);
    if((JSON.stringify(this.packageDetailListData.purchaseInfo) !== '{}' && !this.packageDetailListData.purchaseInfo.showUpgrade && this.packageDetailListData.purchaseInfo.isPurchased)){
      this.router.navigateByUrl('/getquestion/package/'+this.packageDetailListData.purchaseInfo.purchaseDetails+'/'+this.packageId);
    }
    else if(this.packageDetailListData.isparentCousepurchased){
      this.router.navigateByUrl('/getquestion/package/'+this.packageDetailListData.couseIdd+'/'+this.packageId);
    }
  }
  
  public changeLanguage(data) {
    try {
      if (this.mode == 'mal' && typeof (data.mal) != 'undefined') {
        if (data.mal.trim().length > 0) {
          return data.mal;
        }
      }
      return data.en;
    } catch (e) {

    }
    return data.en;
  }
  
  public toFixed(number) {
    return Utils.toFixed(number);
  }

  public NavigatePayment() {
    localStorage.setItem("src", "web");
    this._broadcastService.broadcastLoader(true);
    this.router.navigateByUrl('pay?utm_source=' + this.packageId + "&utm_type=package&utm_purchase=fullversion");

  }
  public gotoPackage(id) {
    //this.router.navigateByUrl('mypackage/' + this.packageId + "/" + id);
    this.router.navigateByUrl('/getquestion/package/'+id+'/'+this.packageId);
  }
  public checkDescription(lang) {
    return Utils.checkDescription(lang);
  }

  public checkImageUrl(url) {
    return Utils.checkImageAvailable(url);
  }
  
  public purchaseNowFreePackage() {
    var purchaseData = {
      "courseId": this.packageId,
      "materialType": "package",
      "paidType": 'trial',
      "paymentID": "",
      "validity": 1
    }
    this.userPurchase(purchaseData);
  }
  public userPurchase(purchaseData) {
    
    var name = this.packageDetailListData.packageName;
    this._broadcastService.broadcastLoader(true);
    this._apiService.post(GlobalConfig.Urls.user_purchase, purchaseData).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        console.log(this.packageDetailListData.is_mock_test);
        console.log(val.data.materialType);
        if(this.packageDetailListData.is_mock_test){
          this.router.navigateByUrl('/getquestion/package/'+val.data.usrCourseId+'/'+val.data.materialId);
        }else{
        if (this.packageDetailListData.is_mock_test) {
          this.router.navigateByUrl('/success/redeem/packages/' + name + '?ismock=yes&materialType=package&courseId=' + val.data.usrCourseId + '&materialid=' + val.data.materialId);
        } else {
          this.router.navigateByUrl('/success/redeem/packages/' + name);
        }
      }
        //
      }
    });
  }
}