import { Component, OnInit } from '@angular/core';

import { AppConfig } from '@app/config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent   {
  title = AppConfig.appTitle;
  
}
