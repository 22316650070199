import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare function hideChat(): any;
declare var $: any;

@Component({
  selector: 'app-learning-layout',
  templateUrl: './learning-layout.component.html'
})
export class LearningLayoutComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) { }
  isMobile: boolean = false;
  ngOnInit() {
    $(".module .trigger ul.locations").css("display", "none");    
    window.scrollTo(0, 0);
    this.activatedRoute.queryParams.subscribe(params => {
      const isMob = params['isMobile'];
      const token = params['withtoken'];
      const isVideo = params['isVideo'];
      this.isMobile = isMob == "1" ? true : false;
      if (this.isMobile && typeof (token) != "undefined") {
        localStorage.setItem('accesstoken', token);
        setTimeout(function () {
          hideChat();
        }, 1000);
      }
      if(isVideo=="1"){
        this.isMobile=true;
        setTimeout(function () {
          hideChat();
        }, 1000);
      }
    });
    $(document).ready(function() {
      $(".module .trigger span").on('click',function(){
        $(this).siblings("ul.locations").toggleClass("filter-show");
      });
      $( "body" ).on( "click",".trigger li", function() {
        $(this).parent('ul.locations').removeClass("filter-show");
      });
    });
  }
}
