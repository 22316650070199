import { Component, OnInit, Input, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@app/core';
import { environment } from '@env/environment';
import { ToasterModule, ToasterService, BodyOutputType } from "angular2-toaster";
import { Router, ActivatedRoute } from '@angular/router';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Utils } from '@app/core/utility/Utils';
import { StarRatingComponent } from 'ng-starrating';
import { GlobalConfig } from '@env/globalconfig';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
declare function callToolTip(): any;
declare function assignAccordion(): any;
declare function removeStick(): any;
@Component({
  selector: 'app-solved-question-paper-detail',
  templateUrl: './solved-question-paper-detail.component.html',
  styleUrls: ['./solved-question-paper-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SolvedQuestionPaperDetailComponent implements OnInit {
  QuestionData: any = [];
  currentQuestionIndex: any = 0;
  questionResult = { userCrsId: "", parentId: "" };
  currentQuestion: any = { "choices": [] };
  correctAnswers: any = [];
  description: string = "";
  isQuestionDone: boolean = false;
  isNextQuestion: boolean = false;
  isCorrectAnswer: number = -1;
  couserId: string = ""; packageid: string = ""; chapterId: string = "";
  timeLeft: number = 0;
  timeTake: number = 0;
  interval; intervalTime;
  subscribeTimer: any;
  mode: string = "en";
  materialType: string = "";
  isEnableMalayalam: boolean = false;
  currentMilestone: string = "";
  quizName: string = "";
  parentId: string = "";
  userCrsId: string = "";
  arrow: boolean = false;
  isSubjectiveType: boolean = false;
  isMockTest: boolean = false;
  mockcheck: boolean = false;
  isMobile: boolean = false;
  gotoLinklabel: string = "";
  isEbook = false;
  stars: number[] = [1, 2, 3, 4, 5];
  public lottieplayer: Object;
  isUserLogin:boolean = false;
  breadcrumbsList: any = [];

  @Input()
    set FilterDetail(parentData: any) {
        this.couserId = parentData.couserId;
        this.packageid = parentData.packageid;
        this.chapterId = parentData.chapterId;
        this.materialType = parentData.materialType;
        this.isMobile = parentData.isMobile;
        this.isEbook = parentData.isEbook;
    }
    constructor(
      private toasterService: ToasterService,
      private _apiService: ApiService,
      private router: Router,
      private activeRoute: ActivatedRoute,
      private _broadcastService: BroadcastService,
      private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService
  
    ) {
      this.lottieplayer = {
        path: 'https://assets9.lottiefiles.com/packages/lf20_x8o8o3zg.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        speed: 1
      };
    }
    choices = [];
  
    drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.choices, event.previousIndex, event.currentIndex);
    }
    updateBreadcrumb(): void {
      const breadcrumbs  =  this.breadcrumbsList;
      this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
    }
  ngOnInit() {    
    this.breadcrumbsList = [
      {
        label: 'Home',
        url: '/'
      }];
    if (localStorage.getItem('accesstoken') == null) {
      this.isUserLogin = false; 
      this.breadcrumbsList.push({
        label: 'Solved Question Papers',
        url: '/solved-question-paper'
      }); 
    } else {
      this.isUserLogin = true;
      this.breadcrumbsList.push({
        label: 'Solved Question Papers',
        url: '/solved-question-paper-dashboard'
      }); 
    }
    this.isQuestionDone = true;
    this._broadcastService.broadcastLoader(false);
    this.currentQuestionIndex = 0;
    this.isCorrectAnswer = -1;
    this.mode = "en";
    this.getQuestion();
  }
  public change() {
    this.mode = this.mode == 'en' ? 'mal' : 'en';
    callToolTip();
  }
  public changeLang(obj) {
    try {
      if (typeof (obj) == "undefined")
        return;
      if (obj[this.mode].trim().length > 0) {
        return obj[this.mode];
      }
      return obj['en'];
    } catch (er) { }
  }
  public changeLangString(strLang) {
    try {
      if (typeof (strLang) == "object") {
        if (strLang[this.mode].trim().length > 0) {
          return strLang[this.mode];
        } else {
          return strLang["en"];
        }
        return;
      }
      if (typeof (strLang) == "undefined")
        return;
      var obj = JSON.parse(strLang);
      if (obj[this.mode].trim().length > 0) {

        return obj[this.mode];
      }
      return obj['en'];
    } catch (err) {

    }
  }
  public callToolTipFun() {
    callToolTip();
  }
  public callToolTipSubjective(data, dataview) {
    this.currentQuestion.questDescription = data;
    if (typeof (dataview.view) == "undefined")
      dataview.view = true;
    else
      dataview.view = !dataview.view;
    callToolTip();

  }

  public checkDescriptionContent(desc) {
    var result = Utils.checkDescriptionContent(desc, this.mode);

    return result;
  }
  public EnableLanguage(lang) {
    if (!this.isSubjectiveType) {
      return Utils.checkDescriptionContent(lang, "mal");
    } else {
      var option = false;
      this.currentQuestion.forEach(element => {
        if (!option) {
          option = Utils.checkDescriptionContent(element.questionTitle, "mal");
        }
      });
      return option;
    }
  }
  public checkOptionAvaliable(opt, input) {
    if (input == "text") {
      if (typeof (opt) == "undefined" || opt.length == 0)
        return true;
      else
        return false;
    } else {
      if (typeof (opt) != "undefined" && opt.length > 0)
        return true;
      else
        return false;
    }

  }
  public getSubquestionColor(idx) {
    if (this.isNextQuestion) {
      return (this.correctAnswers[idx].isCorrect == 'true' || this.correctAnswers[idx].isCorrect == true)
        ? 'green-brd' : 'red-brd';
    }
    else
      return "";
  }
  public getLetter(idx) {
    return Utils.getOptionLetter(idx);
  }
  public chageArrow(arrow) {
    this.arrow = !arrow;
  }
  public getQuestion() {
    this.arrow = false;
    this.isCorrectAnswer = -1;
    this._broadcastService.broadcastLoader(true);
    var data = {};
    if (this.materialType == "package") {
      data = {
        "materialType": "package",
        "packageId": this.packageid,
        "usrCourseId": this.couserId,
      };
    } else {
      data = {
        "usrCourseId": this.couserId,
        "chapterId": this.chapterId,
        "materialType": "course",
        "packageId": this.packageid,

      };
    }

    let response = this._apiService.post(GlobalConfig.Urls.get_solved_question, data);
    response.subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      window.scrollTo(0, 0);
      this.isMockTest = false;
      if (val.status == '200') {

        this.quizName = val.data.quizName;
        if (typeof (val.data.promotion) != "undefined") {
          this.isMockTest = val.data.promotion.is_mock_test;

        } else {
          this.isMockTest = false
        }
        if (typeof (val.data.mock_test) != "undefined") {
          this.mockcheck = val.data.mock_test;
          if (this.mockcheck) {
            this.gotoLinklabel = 'Mock Test';
          } else {
            this.gotoLinklabel = this.materialType;
          }
        } else {
          this.mockcheck = false;
          this.gotoLinklabel = this.materialType;
        }
        //console.log(val.data);
        if (val.message == "Question not Found.") {
          this.isQuestionDone = true;
          this.questionResult = val.data;
          console.log('iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
          console.log(this.questionResult)
          this.userCrsId = this.questionResult.userCrsId;
          this.parentId = this.questionResult.parentId;
          removeStick();
        } else {
          this.isQuestionDone = false;
          this.QuestionData = val.data;
          try {
            this.isSubjectiveType = val.data.question.length >= 0;
          } catch (ex) {
            this.isSubjectiveType = false;
          }
          if (this.isSubjectiveType) {

            this.currentQuestion = val.data.question;
            setTimeout(function () {
              assignAccordion();
            }, 500);

          } else {
            if (val.data.question.questionType == "subquestion") {
              val.data.question.choices.forEach(element => {
                element.isCorrectAnswer = "";
              });
            }
            console.log('iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
            this.currentQuestion = val.data.question;
            this.choices = [];
            if (this.currentQuestion.questionType == "matchthefollowing") {
              this.choices = this.currentQuestion.formation[0].right;
            }
            if (typeof (val.data.isPractiseType) != "undefined" && val.data.isPractiseType == true) {
              this.currentQuestion.isFreeQuestion = true;
            }

            this.currentQuestionIndex = val.data.count + 1;
            this.timeLeft = this.currentQuestion.answerTime / 1000;
            // if (this.timeLeft > 0) {
            //   this.timeLeft = this.timeLeft + 1;
            // }
            this.timeTake = 0;
            this.isNextQuestion = false;
            this.pauseTimer();
            this.startTimer();
            this.currentMilestone = val.data.currentMileStone;
          }
          callToolTip();
          if(this.isUserLogin){
            this.breadcrumbsList.push({
              label:'Solved Question Name',
              url:'/solved-question-detail-dashboard/solved-question-paper/'+this.couserId
            });
          }else{
            this.breadcrumbsList.push({
              label:'Solved Question Name',
              url:'/solved-question-details/solved-question-paper/'+this.couserId
            });
          }
          this.breadcrumbsList.push({
            label: val.data.quizName+'('+val.data.totalQuestions+')',
            url: ''
          },);
          this.updateBreadcrumb();
        }
      }
      else {
        this.isQuestionDone = true;
      }
    });

  }
  public getQuestionType(questionType) {
    if (questionType == 'single')
      return "Choose one answer that applies best";
    if (questionType == 'multiple')
      return "Choose all answers that applies best";
    if (questionType == 'matchthefollowing')
      return "Match Items";
    if (questionType == 'subquestion')
      return "Answer the questions";
    if (questionType == 'subjective')
      return "Subjective question";
  }
  public selectOption(idx, opt) {
    this.currentQuestion.choices[idx].isCorrectAnswer = opt;
  }
  public selectTitle(question) {
    try {
      if (question == "")
        return "Select Correct Answer";
      else
        return question[this.mode];
    } catch (er) {
      return "Select Correct Answer";
    }
  }
  public verifyAnswer(questionId, choices, questionType) {//, skipValidation
    var tempChoices = [];
    //if (!skipValidation) {
    var questionSelect = false;
    if (questionType == 'subquestion') {
      questionSelect = true;
    }
    var idx = 0;
    choices.forEach(element => {
      if (questionType == 'single') {
        tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": false });
      } else {
        if (questionType == 'multiple') {
          if (typeof (element.isCorrectAnswer) != "undefined" && element.isCorrectAnswer) {
            questionSelect = true;
            tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": element.isCorrectAnswer });
          }
        }
        else if (questionType == 'matchthefollowing') {
          questionSelect = true;
          if (typeof (this.choices[idx].choiceResponse) != "undefined")
            delete this.choices[idx].choiceResponse;
          if (typeof (this.choices[idx].choiceIdentity) != "undefined")
            delete this.choices[idx].choiceIdentity;

          tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": JSON.stringify(this.choices[idx]) });
        }

        else if (questionType == 'subquestion') {

          if (typeof (element.options) == "undefined" || element.options.length == 0) {
            if ((typeof (element.isCorrectAnswer) == "undefined" || element.isCorrectAnswer.trim().length == 0)) {
              questionSelect = false;
            } else {
              tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": JSON.stringify({ "en": element.isCorrectAnswer, "mal": "" }), options: false });
            }
          } else {
            if (typeof (element.isCorrectAnswer) == "string") {
              questionSelect = false;
            } else {
              tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": JSON.stringify(element.isCorrectAnswer), options: true });
            }
          }

        }
      }
      idx = idx + 1;
    });
    if (questionType == 'single') {
      if (this.isCorrectAnswer >= 0) {
        tempChoices[this.isCorrectAnswer].isCorrectAnswer = true;
        questionSelect = true;
        var singleChoiceAnswer = [];
        tempChoices.forEach(element => {
          if (element.isCorrectAnswer) {
            singleChoiceAnswer.push(element);
          }
        });
        tempChoices = singleChoiceAnswer;
      } else {
        questionSelect = false;
      }
    }
    if (!questionSelect) {
      this.toasterService.clear(); this.toasterService.pop("error", 'Question', questionType == 'subquestion' ? 'fill all answer' : "select atleast one answer");
      return;
    }
    // } else {
    //   choices.forEach(element => {
    //     if (questionType == 'single' || questionType == 'multiple') {
    //       tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": false });
    //     } else {
    //       tempChoices.push({ "choiceId": element._id, "isCorrectAnswer": '' });
    //     }
    //   });
    // }
    this._broadcastService.broadcastLoader(true);
    var data = {}
    if (this.materialType == "package") {
      data = {
        "choices": tempChoices,
        "elapsedTime": this.timeTake * 1000,
        //"elapsedTime": skipValidation ? this.currentQuestion.answerTime : (this.currentQuestion.answerTime - this.timeLeft * 1000),
        "packageId": this.packageid,
        "questionId": questionId,
        "materialType": "package",
        "usercourse": this.couserId,
        "chapter": ""
      };
    } else {
      data = {
        "chapter": this.chapterId,
        "choices": tempChoices,
        "elapsedTime": this.timeTake * 1000,
        //"elapsedTime": skipValidation ? this.currentQuestion.answerTime : (this.currentQuestion.answerTime - this.timeLeft * 1000),
        "packageId": this.packageid,
        "questionId": questionId,
        "materialType": "course",
        "usercourse": this.couserId

      };
    }
    let response = this._apiService.post(GlobalConfig.Urls.verifyanswer, data);
    response.subscribe((val) => {
      this._broadcastService.broadcastLoader(false);

      if (val.status == '200') {

        this.pauseTimer();
        // || this.materialType == "package"
        // this.isMockTest = val.data.promotion.is_mock_test;
        let { data = [] } = val
        if (data && data.length > 0) {
          let { is_mock_test = false } = data[0]
          this.isMockTest = is_mock_test
        }
        if (!this.currentQuestion.isFreeQuestion) {
          this.getQuestion();
        } else {

          this.isNextQuestion = true;
          this.correctAnswers = val.data[0].correctAnswers;
          this.currentQuestion.choices.forEach(element => {
            element.isSubmitCorrectAns = "false";
            this.correctAnswers.forEach(ans => {
              if (ans._id == element._id) {
                element.isSubmitCorrectAns = ans.is_correct;
              }
            });
          });
          this.description = val.data[0].description.en;

        }

      }
      else {

      }
    });

  }
  public checkOption(questionType, isCorrectAnswer, isSubmitCorrectAns, idx) {
    if (isSubmitCorrectAns == "true") {
      return "green-brd";
    }
    if (questionType == 'multiple') {
      if (isCorrectAnswer) {
        return 'red-brd';
      } else {
        return "";
      }
    } else if (questionType == 'single') {
      if (isCorrectAnswer == this.isCorrectAnswer && idx == this.isCorrectAnswer) {
        return 'red-brd';
      } else {
        return "";
      }
    }
  }
  public retryQuestion() {

    var data = {};
    if (this.materialType == "course") {
      data = {
        "usercourseId": this.couserId,
        "chapterId": this.chapterId,
        "materialType": "course",
        "packageId": this.packageid,

      };
    } else {
      data = {
        "usercourseId": this.couserId,
        "chapterId": "",
        "materialType": "package",
        "packageId": this.packageid,

      };
    }

    this._broadcastService.broadcastLoader(true);
    let response = this._apiService.post(GlobalConfig.Urls.retry, data);
    response.subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == '200') {
        // if (this.materialType == "package") {
        //   this.router.navigateByUrl('/mypackage/' + this.packageid + "/" + this.couserId);
        // } else {
        //   this.router.navigateByUrl('/mychapterdetails/' + this.couserId + "/" + this.chapterId);
        // }
        this.currentQuestionIndex = 0;
        this.getQuestion();
      }

    });

  }
  public mySummaryQuestion() {
    if (this.materialType == "package") {
      this.router.navigateByUrl('/mysummary/package/' + this.couserId + "/" + this.packageid);
    } else {
      let isEbook = 0;
      if (this.isEbook) {
        isEbook = 1;
      }
      this.router.navigateByUrl('/mysummary/course/' + this.couserId + "/" + this.chapterId + "/" + this.packageid + "?isEbook=" + isEbook);
    }
  }

  oberserableTimer() {
    const source = this.interval(1000, 2000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  startTimer() {
    this.interval = setInterval(() => {

      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft < 0) {
          this.timeLeft = 0;
          clearInterval(this.interval);
        }
      } else {
        clearInterval(this.interval);
        //this.verifyAnswer(this.currentQuestion._id, this.currentQuestion.choices, this.currentQuestion.questionType, true);
      }
    }, 1000);
    this.intervalTime = setInterval(() => {

      this.timeTake++;

    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
    clearInterval(this.intervalTime);
  }
  discussionQuestion: string = "";
  public submitDiscusstion() {
    if (this.discussionQuestion.trim().length == 0) {
      this.toasterService.clear(); this.toasterService.pop("error", 'Discussion', "Enter your topic");
      return;
    }
    var data = {};
    if (this.materialType == "course") {
      data = {
        "userCoursesId": this.couserId,
        "chapterId": this.chapterId,
        "questionId": this.currentQuestion._id,
        "courseType": this.materialType,
        "topic": this.discussionQuestion

      };
    } else {
      data = {
        "userCoursesId": this.couserId,
        "questionId": this.currentQuestion._id,
        "courseType": this.materialType,
        "topic": this.discussionQuestion

      };
    }
    this._broadcastService.broadcastLoader(true);
    let response = this._apiService.post(GlobalConfig.Urls.start_discussion, data);
    response.subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == '200') {
        this.discussionQuestion = "";
        this.toasterService.pop("success", 'Discussion', val.message);
      }
      else {
        this.toasterService.pop("success", 'Discussion', val.message);
      }
    });
  }
  feedback: string = "";
  rating: any = "5";
  quickReview: any = 'Improve question level hard';
  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    this.rating = $event.newValue;
  }
  countStar(star) {
    this.rating = star;
  }
  public submitFeedback() {

    var data = {};
    if (this.materialType == "course") {
      data = {
        feedback: this.feedback,
        rating: this.rating,
        chapterId: this.chapterId,
        materialType: this.materialType,
        usrCourseId: this.couserId,
        packageId: this.packageid,
        quickReview: this.rating != '5' ? this.quickReview : ''
      };
    } else {
      data = {
        packageId: this.packageid,
        materialType: this.materialType,
        rating: this.rating,
        feedback: this.feedback,
        packageUsrId: this.couserId,
        quickReview: this.rating != '5' ? this.quickReview : ''
      };
    }

    this._broadcastService.broadcastLoader(true);
    let response = this._apiService.post(GlobalConfig.Urls.save_feedback, data);
    response.subscribe((val) => {
      this._broadcastService.broadcastLoader(false);

      if (val.status == '200') {
        this.feedback = "";
        this.quickReview = "Improve question level hard";
        this.toasterService.pop("success", 'Feedback', val.data);
      }
      else {
        this.toasterService.pop("success", 'Feedback', val.message);
      }
    });
  }
  public gotoPackage() {
    if (this.materialType == "package") {
      if (this.mockcheck) {
        this.router.navigateByUrl('/mocktest-dashboard');

      } else {
        this.router.navigateByUrl('/mypackage/' + this.packageid + "/" + this.couserId);
      }
    } else {
      this.router.navigateByUrl('/mychapterdetails/' + this.couserId + "/" + this.chapterId);
    }
  }
  public gotoCourse() {
    this.router.navigateByUrl('/mycoursedetails/course/' + this.couserId);
  }
  // public enableDisclaimer(disclaimer){
  //   if(typeof(disclaimer)=="undefined" || disclaimer==null || disclaimer=="")
  //     return false;
  //   else
  //   return true;

  // }
  public removePopOver(id) {
    $('.webui-popover').remove();
  }
  public checkLO(data) {
    if (typeof (data) == "undefined" || data == 0 || data == "" || data == null || data == "0") {
      return false;
    } else return true;
  }
  public checkImageUrl(url) {
    return Utils.checkImageAvailable(url);
  }
  public EnableLanguageDesc(lang) {
    return Utils.checkLanguageAvaliable(lang);
  }
  counter(i: number) {
    return new Array(i);
  } 
  public gotoChapter(course,chapter) {
    this.router.navigateByUrl('/mychapterdetails/' + course + "/" + chapter);
  }
  public toFixed(number) {
    return Utils.toFixed(number);
  }
}
