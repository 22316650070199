import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
declare function readyFunction(type): any;

@Component({
    selector: 'mocktest-packages-list',
    templateUrl: './mocktest-packages.component.html'
  })
export class MocktestPackagesComponent implements OnInit {
    packageListData: any = [];
    star: any = [{ "no": 1, "fill": "no" }, { "no": 2, "fill": "no" }, { "no": 3, "fill": "no" }, { "no": 4, "fill": "no" }, { "no": 5, "fill": "no" }];

    price: string = "all";
    subject: string = "all";
    level: string = "all";
    url: string = "";
    subjects: any = [];
    levelList: any = [];
    priceList: any = [];
    isInitialLoading: boolean = false;
    isScrollLoading: boolean = false;
    selectedSubject: string = "All Subject";
    selectedLavel: string ="All Level";
    selectedPrice: string ="All Price";
    isUserLogin: boolean;
    latesId: any = [];
    @Input()
    set UrlDetail(parentData: any) {
        this.url = parentData;
    }
    constructor(
      private _broadcastService: BroadcastService,
      private _apiService: ApiService,
      private router: Router,private activeRoute: ActivatedRoute,
    ){
      
    }
    ngOnInit() {
      this.levelList = [
        { name: "+1", value: "plusone" },
        { name: "+2", value: "plustwo" },
        //{ name: "Degree", value: "degree" },
        //{ name: "Advanced", value: "advanced" },
      ];
      this.priceList = [
        { name: "Free", value: "free" },
        { name: "Paid", value: "paid" },
      ];
      if (localStorage.getItem('accesstoken') == null) {
        this.isUserLogin = false;  
      } else {
        this.isUserLogin = true;
      }


      this.activeRoute.queryParams.subscribe(queryParams => {
        const parmas = { ...queryParams.keys, ...queryParams };
  
        // if (parmas['free']) {
        //   this.price = parmas['free'];
        // }
        // else {
        //   this.price = "all";
        // }
        if (parmas['subject']) {
          this.subject = parmas['subject'];
          this.filterSubject(parmas['subject'])
         
        }
        else {
          this.subject = "all";
          this.filterSubject(parmas['subject']);
        }
        if (parmas['level']) {
          this.level = parmas['level'];
        }
        else {
          this.level = "all";
        }
      });


      if (this.router.url.indexOf("/mocktest") > -1) {
        this.url = GlobalConfig.Urls.public_mocktest;
      }else{
        this.url = GlobalConfig.Urls.public_package;
      }
       this._apiService.get(GlobalConfig.Urls.latest_mocktest + "?free=" + this.price + "&subject=" + this.subject + "&level=" + this.level + "&pagin=true&page=1").subscribe((val) => {
         if (val.status == 200) {
           if (this.router.url.indexOf("/mocktest") > -1) {            
               this.latesId = [];
               val.data.quizes.forEach(element => {
                 this.latesId.push(element._id);
               });
           } else {
             val.data.forEach(element => {
               this.latesId.push(element._id);
             });
           }  
         }
         this.getPackageList(1);
       });
      this._apiService.get(GlobalConfig.Urls.public_subject).subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        if (val.status == 200) {
          this.subjects = val.data;

          this.filterSubject(this.subject);
          this.filterLavel(this.level);
          this.filterPrice(this.price);
        }
      });
    }
    public getPackageList(page: number) {
      //this._broadcastService.broadcastLoader(true);
      this.isScrollLoading = true;      
      this.filterSubject(this.subject);
      this.filterLavel(this.level);
      this.filterPrice(this.price);
      this._apiService.get(this.url + "?free=" + this.price + "&subject=" + this.subject + "&level=" + this.level + "&pagin=true&page=" + page).subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        this.historyPush();
        this.isInitialLoading = true;
        this.isScrollLoading = false;
        if (val.status == 200) {
          if (this.router.url.indexOf("/mocktest") > -1) {
              if (page == 1) {
                //window.scrollTo(0, 0);
                this.packageListData = [];
              }
              this.pageNumber = page;
              val.data.quizes.forEach(element => {
                if (this.latesId.indexOf(element._id) !== -1) {
                  element.latesFlag = true;
                }else{
                  element.latesFlag = false;
                }
                this.packageListData.push(element);
              });
              setTimeout(function () {
                readyFunction('');
              }, 500);    
          } else {
            // normal pacakges    
            val.data.forEach(element => {
              if (this.latesId.indexOf(element._id) !== -1) {
                element.latesFlag = true;
              }else{
                element.latesFlag = false;
              }
              this.packageListData.push(element);
            });
          }
        }
  
      });
  
    }    
    public checkImageUrl(url) {
      return Utils.checkImageAvailable(url);
    }
    counter(i: number) {
      return new Array(i);
    }
    pageNumber: any = 1;
    onScroll() {
      if (!this.isScrollLoading) {
        this.pageNumber = this.pageNumber + 1;
        this.getPackageList(this.pageNumber);
      } else {
        console.log('progress');
      }
    }
    public filterSubject(id) {   
      var name = "All Subject";
      this.subjects.forEach(element => {
        if (element.id == id) {
          name = element.name;
        }
      });
      this.selectedSubject = name;
    }  
    public filterLavel(id) {
      var name = "All Level";
      this.levelList.forEach(element => {
        if (element.value == id) {
          name = element.name;
        }
      });
      this.selectedLavel = name;
    }
    public filterPrice(id) {
      var name = "All Price";
      this.priceList.forEach(element => {
        if (element.value == id) {
          name = element.name;
        }
      });
      this.selectedPrice = name;
    }
    historyPush() {
      let locat = location.href;
      if (location.href.indexOf("?") > -1)
        locat = location.href.substring(0, location.href.indexOf("?"));
      let queryString = [];
      if (this.price) {
        queryString.push("free=" + this.price);
      }
      if (this.subject) {
        queryString.push("subject=" + this.subject);
      }
      if (this.level) {
        queryString.push("level=" + this.level);
      }
      if (queryString.length > 0) {
        locat = locat + "?" + queryString.join("&");
      }
      history.replaceState(null, null, location.origin);
      history.pushState(null, null, locat);
    }


    packageDetails(packageDetailId){

      var detail = { "packageId": packageDetailId };
    //let courseDetails = this._apiservice.post(GlobalConfig.Urls.public_package_details, detail);


    this._apiService.post(GlobalConfig.Urls.public_package_details, detail).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      
      console.log(val);

      if(JSON.stringify(val.data.purchaseInfo) !== '{}' && !val.data.purchaseInfo.showUpgrade && val.data.purchaseInfo.isPurchased){
        this.router.navigateByUrl('/getquestion/package/'+val.data.purchaseInfo.purchaseDetails+'/'+val.data._id);
      }else{
        this.router.navigateByUrl('/tests-dashboard/'+val.data.packageName+'/'+val.data._id);
      }

    });


    }
}