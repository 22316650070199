import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NotFoundComponent } from './core/components';
import { LearningLayoutComponent } from './shared';
import { PrivateLayoutComponent } from './shared/components/layout-v2/private-layout/private-layout.component';
import { DashboardComponent } from './modules-v2/dashboard/dashboard.component';

const routes: Routes = [
  // { path: 'dashboard', component: DashboardComponent,pathMatch : 'full', },
  // { path: 'reports', loadChildren: () => import('./v2/reports/reports.module').then(m => m.ReportsModule)},
  // { path: 'ebooks', loadChildren: () => import('./v2/ebooks/ebooks.module').then(m => m.EbooksModule)},
  // { path: 'courses', loadChildren: () => import('./v2/courses/courses.module').then(m => m.CoursesModule)},
  // { path: 'resources', loadChildren: () => import('./v2/resources/resources.module').then(m => m.ResourcesModule)},
  // { path: 'mock-test', loadChildren: () => import('./v2/mock-test/mock-test.module').then(m => m.MockTestModule)},
  // { path: 'profile', loadChildren: () => import('./v2/profile/profile.module').then(m => m.ProfileModule)},
  // {
  //   path:'',
  //   redirectTo:'dashboard',
  //   pathMatch:'full'
  // },
  {
    path: '',
    component: LearningLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: './modules-v2/home/home.module#HomeModule'
      }, {
        path: '',
        loadChildren: './modules-v2/home/home.module#HomeModule'
      }

    ],
  },
  {
    path: 'dashboard',
    component: PrivateLayoutComponent,
    children: [
    {
    path: '',
    loadChildren: './modules-v2/dashboard/dashboard.module#DashboardModule'
    }
    
    ],
    data: {
      title: '',
    }
    },
  {
    path: 'login',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/auth/login/login.module#LoginModule'
      }

    ]
  },
  {
    path: 'registration',
    component: LearningLayoutComponent,
    children: [

      // {
      //   path: 'email',
      //   loadChildren: './modules/auth/registration/registration.module#RegistrationModule'
      // },
      {
        path: '',
        loadChildren: './modules-v2/auth/registration/registration.module#RegistrationModule'
      }
    ]
  },
  {
    path: 'setpassword',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/auth/setpassword/setpassword.module#SetPasswordModule'
      }

    ]
  },
  {
    path: 'forgotpassword',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/auth/forgetpassword/forgetpassword.module#ForgetPasswordModule'
      }

    ]
  },
  {
    path: 'con-web-view',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/contact/contact.module#ContactModule'
      }

    ]
  },
  {
    path: 'contact',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/contact/contact.module#ContactModule'
      }

    ]
  },
  {
    path: 'subscribe',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/subscription/subscribe.module#SubscribeModule'
      }

    ]
  },
  {
    path: 'video_webview',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/video-webview/video-webview.module#VideoWebViewModule'
      }

    ]
  },

  {
    path: 'profile',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/profile/profile.module#ProfileModule'
      }

    ]
  },
  {
    path: 'device-update',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/device-update/deviceupdate.module#DeviceupdateModule',
      }

    ]
  },
  {
    path: 'myeksalar',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/mycourse/mycourse.module#MyCourseModule'
      }

    ]
  },
  {
    path: 'mytransaction',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/mytransaction/mytransaction.module#MyTransactionModule'
      }

    ]
  },
  {
    path: 'mycoursedetails',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/my-course-details/my-course-details.module#MyCourseDetailsModule',
      
      }
      
    ]
  },

  {
    path: 'pay',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/pay/pay.module#PayModule'
      }

    ]
  },
  {
    path: 'paySubscribe',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/pay-subscription/pay-subscription.module#PaySubscriptionModule'
      }

    ]
  },
  {
    path: 'paybook',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/paybook/paybook.module#PayBookModule'
      }

    ]
  },
  {
    path: 'success/:ptype/:mtype/:mname',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/paysuccess/paysuccess.module#PaySuccessModule'
      }

    ]
  },

  {
    path: 'eksalar-courses',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/course/course.module#CourseModule'
      }
    ]
  },
  {
    path: 'eksalar-courses-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/course-dashboard/course-dashboard.module#CourseDashboardModule'
      }

    ]
  },
  {
    path: 'eksalar-exam-packages',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package/package.module#PackageModule'
      }

    ]
  },

  {
    path: 'mocktest',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package/package.module#PackageModule'
      }

    ]
  },
  {
    path: 'solved-question-paper',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/solved-question-paper/solved-question-paper.module#SolvedQuestionPaperModule'
      }

    ]
  },
  {
    path: 'solved-question-paper-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/solved-question-paper-dashboard/solved-question-paper-dashboard.module#SolvedQuestionPaperDashboardModule'
      }

    ]
  },
  {
    path: 'solved-question-details/:type/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/solved-question-details/solved-question.module#SolvedQuestionModule'
      }

    ]
  },
  {
    path: 'solved-question-detail-dashboard/:type/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/solved-question-detail-dashboard/solved-question-dashboard.module#SolvedQuestionDashboardModule'
      }

    ]
  },
    {
    path: 'eksalar-exam-packages-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-dashboard/package-dashboard.module#PackageDashboardModule'
      }

    ]
  },

  {
    path: 'mocktest-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-dashboard/package-dashboard.module#PackageDashboardModule'
      }

    ]
  },
  {
    path: 'eksalar-courses-ebook',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/course-ebook/course-ebook.module#CourseEbookModule'
      }

    ]
  },
  {
    path: 'eksalar-text-book-solutions',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/testbook-solutions/testbook-solutions.module#TestBookSolutionsModule'
      }

    ]
  },
  {
    path: 'eksalar-text-book-solutions-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/testbook-solutions-dashboard/testbook-solutions-dashboard.module#TestBookSolutionsDashboardModule'
      }

    ]
  },
  {
    path: 'eksalar-courses-ebook-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/course-ebook-dashboard/course-ebook-dashboard.module#CourseEbookDashboardModule'
      }

    ]
  },

  {
    path: 'store',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/store/store.module#StoreModule'
      }

    ]
  },
  {
    path: 'aboutus',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/aboutus/aboutus.module#AboutUsModule'
      }

    ]
  },
  {
    path: 'faq-old',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/faq/faq.module#FAQModule'
      }

    ]
  },
  {
    path: 'privacy',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/privacy/privacy.module#PrivacyModule'
      }

    ]
  },
  {
    path: 'faq',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/faq/faq.module#FaqModule'
      }

    ]
  },
  {
    path: 'termsandconditions',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsModule'
      }

    ]
  },

  {
    path: 'course-details/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter/chapter.module#ChapterModule'
      }

    ]
  },
  {
    path: 'course-details/:name/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter/chapter.module#ChapterModule'
      }

    ]
  },
  {
    path: 'course-detail-ebook/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter/chapter.module#ChapterModule'
      }

    ]
  },
  {
    path: 'course-detail-ebook/:name/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter/chapter.module#ChapterModule'
      }

    ]
  },
  {
    path: 'course-details-dashboard/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter-dashboard/chapter-dashboard.module#ChapterDashboardModule'
      }

    ]
  },
  {
    path: 'course-details-dashboard/:name/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter-dashboard/chapter-dashboard.module#ChapterDashboardModule'
      }

    ]
  },
  {
    path: 'course-details-dashboard-search/:name/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter-dashboard-search/chapter-dashboard-search.module#ChapterDashboardSearchModule'
      }

    ]
  },
  {
    path: 'course-details-home-search/:name/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter-dashboard-search/chapter-dashboard-search.module#ChapterDashboardSearchModule'
      }

    ]
  },
  {
    path: 'course-detail-ebook-dashboard/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter-dashboard/chapter-dashboard.module#ChapterDashboardModule'
      }

    ]
  },
  {
    path: 'course-detail-ebook-dashboard/:name/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/chapter-dashboard/chapter-dashboard.module#ChapterDashboardModule'
      }

    ]
  },
  {
    path: 'discussion',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/discussion/discussion.module#DiscussionModule'
      }

    ]
  },
  {
    path: 'discussiondetails/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/discussion-details/discussion-details.module#DiscussionDetailsModule'
      }

    ]
  },

  {
    path: 'mycoursedetails/:type/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/my-course-details/my-course-details.module#MyCourseDetailsModule',
    
      }
    ],
    data: {
      title: '',
    }
  },
  {
    path: 'mypackage/:id/:uid',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/mypackage/mypackage.module#MyPackageModule'
      }

    ]
  },

  {
    path: 'mychapterdetails/:cid/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/my-course-chapter/my-course-chapter.module#MyCourseChapterModule'
      }
    ],
    data: {
      title: '',
    }
  },
  {
    path: 'previewchapter/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/preview/chapter/chapter.module#PreviewChapterModule'
      }

    ]
  },
  {
    path: 'getquestion/course/:cid/:id/:pid',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/get-question/get-question.module#GetQuestionModule'
      }

    ],
    data: {
      title: '',
    }
  },
  {
    path: 'getsolvedquestion/course/:cid/:id/:pid',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/get-solved-question/get-solved-question.module#GetSolvedQuestionModule'
      }

    ]
  },
  {
    path: 'getsolvedquestion-dashboard/course/:cid/:id/:pid',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/get-solved-question-dashboard/get-solved-question-dashboard.module#GetSolvedQuestionDashboardModule'
      }

    ]
  },
  {
    path: 'previewQuestion/:id/:pid',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/preview/question/question.module#QuestionModule'
      }

    ]
  },
  {
    path: 'getquestion/package/:cid/:pid',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/get-question/get-question.module#GetQuestionModule'
      }

    ]
  },
  {
    path: 'mysummary/course/:cid/:id/:pid',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/my-summary/my-summary.module#MySummaryModule'
      }

    ]
  },
  {
    path: 'mysummary/package/:cid/:pid',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/my-summary/my-summary.module#MySummaryModule'
      }

    ]
  },
  {
    path: 'resources',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/previous-question/previous-question.module#PreviousQuestionModule'
      }

    ]
  },
  {
    path: 'resources-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/previous-question-dashboard/previous-question-dashboard.module#PreviousQuestionDashboardModule'
      }

    ]
  },
  {
    path: 'packagedetail/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-details/package-details.module#PackageDetailModule'
      }

    ]
  },
  {
    path: 'tests/:slug/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-details/package-details.module#PackageDetailModule'
      }

    ]
  },
    {
    path: 'eksalar-offline-book',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/offline-book/offline-book.module#OfflineBookModule'
      }

    ]
  },
  {
    path: 'packagedetail-dashboard/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-details-dashboard/package-details-dashboard.module#PackageDetailDashboardModule'
      }

    ]
  },
  {
    path: 'tests-dashboard/:slug/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-details-dashboard/package-details-dashboard.module#PackageDetailDashboardModule'
      }

    ]
  },
  {
    path: 'tests-dashboard-search/:slug/:id',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-details-dashboard-search/package-details-dashboard-search.module#PackageDetailDashboardSearchModule'
      }

    ]
  },
  {
    path: 'tests-home-search/:slug/:id',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/package-details-dashboard-search/package-details-dashboard-search.module#PackageDetailDashboardSearchModule'
      }

    ]
  },
  {
    path: 'login-old',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/auth/login/login.module#LoginModule'
      }

    ]
  },
  {
    path: 'eksalar-revision-test',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/revision-test/revision-test.module#RevisionTestModule'
      }
    ]
  },
  {
    path: 'reports',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/study-report/study-report.module#StudyReportModule'
      }

    ]
  },
  {
    path: 'revision-series',
    component: LearningLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/revision-series/revision-series.module#RevisionSeriesModule'
      }
    ]
  },
  {
    path: 'revision-series-dashboard',
    component: PrivateLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules-v2/revision-series-dashboard/revision-series-dashboard.module#RevisionSeriesDashboardModule'
      }

    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
