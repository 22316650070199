import { Injectable , EventEmitter} from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class NavService {
    navchange: EventEmitter<number> = new EventEmitter();
    constructor() {}
    emitNavChangeEvent(number) {
      this.navchange.emit(number);
    }
    getNavChangeEmitter() {
      return this.navchange;
    }
  }