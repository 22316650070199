import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HtmlModule } from '@app/core/pipe/HtmlModule';

import { FileUploadComponent } from './components/layout/file-upload/file-upload.component';
import { MultiSelectComponent } from './components/layout/multi-select/multi-select.component';

import { DataLoadResolve } from './components/layout/multi-select/multi-select.resolve';
import { LearningLayoutComponent, LearningFooterComponent, LearningHeaderComponent, EksalarPaginationComponent,SidenavComponent,PrivateHeaderComponent } from './components/layout-v2';
import { PrivateLayoutComponent } from './components/layout-v2/private-layout/private-layout.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { NoRightClickDirective } from './directive/no-right-click.directive';
import { FixedCtaComponent } from './components/fixed-cta/fixed-cta.component';
import { CourseListComponent } from './components/course-list/course-list.component';
import { EbookListComponent } from './components/ebook-list/ebook-list.component';
import { MocktestPackagesComponent } from './components/mocktest-packages/mocktest-packages.component';
import { CourseEbookComponent } from './components/course-ebook/course-ebook.component';
import { offlineBookListComponent } from './components/offline-book/offline-book-list.component';
import { MockTestComponent } from './components/mock-test/mock-test.component';
import { TestEbookListComponent } from './components/testbook-solutions/testbook-solutions.component';
import { MocktestLatestComponent } from './components/mocktest-latest/mocktest-latest.component';
import { SolvedQuestionPaperComponent } from './components/solved-question-paper/solved-question-paper.component';
import { SolvedQuestionChapterComponent } from './components/solved-question-chapter/solved-question-chapter.component';
import { ResourceListComponent } from './components/resource-list/resource-list.component';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { SolvedQuestionPaperDetailComponent } from './components/solved-question-paper-detail/solved-question-paper-detail.component';
import { MinuteModule } from '@app/core/pipe/MinuteModule';
import { RevisionTestListComponent } from './components/revision-test-list/revision-test-list.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RevisionSeriesListComponent } from './components/revision-series-list/revision-series-list.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
   url: localStorage.getItem('url')+'upload',
   headers:{ 'x-auth': localStorage.getItem('accessToken') },
   maxFilesize: 50,
   acceptedFiles: 'image/*'
 };

@NgModule({
  declarations: [
    LearningLayoutComponent,
    PrivateLayoutComponent,
    LearningFooterComponent,
    LearningHeaderComponent,
    FileUploadComponent,
    MultiSelectComponent,
    PrivateLayoutComponent,
    EksalarPaginationComponent,
    ConfirmationDialogComponent,
    NoRightClickDirective,
    SidenavComponent,
    PrivateHeaderComponent,
    FixedCtaComponent,
    CourseListComponent,
    EbookListComponent,
    TestEbookListComponent,
    MocktestPackagesComponent,
    SolvedQuestionPaperComponent,
    CourseEbookComponent,
    offlineBookListComponent,
    MockTestComponent,
    MocktestLatestComponent,
    SolvedQuestionChapterComponent,
    ResourceListComponent,
    SolvedQuestionPaperDetailComponent,
    RevisionTestListComponent,
    RevisionSeriesListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    DropzoneModule,
    NgMultiSelectDropDownModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    InfiniteScrollModule,
    HtmlModule,
    NgDynamicBreadcrumbModule,
    MinuteModule,
    PdfViewerModule
   
  ],
  exports: [
    LearningFooterComponent,
    LearningHeaderComponent,
    LearningLayoutComponent,
    PrivateLayoutComponent,
    FileUploadComponent,
    MultiSelectComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    EksalarPaginationComponent,
    ConfirmationDialogComponent,
    NoRightClickDirective,
    FixedCtaComponent,
    CourseListComponent,
    EbookListComponent,
    TestEbookListComponent,
    MocktestPackagesComponent,
    SolvedQuestionPaperComponent,
    CourseEbookComponent,
    offlineBookListComponent,
    MockTestComponent,
    MocktestLatestComponent,
    SolvedQuestionChapterComponent,
    NgDynamicBreadcrumbModule,
    ResourceListComponent,
    SolvedQuestionPaperDetailComponent,
    RevisionTestListComponent,
    RevisionSeriesListComponent
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    DataLoadResolve
  ],
})
export class SharedModule {}
