import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http'
import { ApiService } from './api.service';
import { GlobalConfig } from '@env/globalconfig';
import { environment } from '@env/environment';
@Injectable({
    providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
    constructor(private injector: Injector, private _apiService: ApiService) { }
    handleError(error: any) {
        var tempError = error;
        const router = this.injector.get(Router);
        if (sessionStorage.getItem("error") == null)
            sessionStorage.setItem("error", tempError.message + "-" + router.url);
        if (sessionStorage.getItem("error") == tempError.message + "-" + router.url) { }
        else {
            sessionStorage.setItem("error", tempError.message + "-" + router.url);

            var tempErrorRecord = {

                "error": tempError.message,
                "lineno": "",
                "url": router.url,
                "token": localStorage.getItem('accesstoken'),
                "timestamp": new Date().getTime(),
                "filename": "",
                "supportingInfo": tempError.stack
            };
            if (environment.production)
             {
                let response = this._apiService.post(GlobalConfig.Urls.saveError, tempErrorRecord);
                response.subscribe((val) => {
                    //console.log(val);
                    if (val.status == '200') {

                    }
                });
            }
        }
        console.log(error);
    }
}
