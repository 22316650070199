import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-cta-detail',
  templateUrl: './fixed-cta.component.html',
  styleUrls: ['./fixed-cta.component.scss'],
})

export class FixedCtaComponent implements OnInit {
  
  constructor() {}

  ngOnInit() {}  
}