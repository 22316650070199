import { Component, OnInit, EventEmitter, Input, Output,SimpleChanges } from '@angular/core';
import { ApiService } from '@app/core';
import { environment } from '@env/environment';
declare var Razorpay: any;
import { Router } from '@angular/router';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
import { ToasterService } from 'angular2-toaster';
declare function callToolTip(): any;

@Component({
  selector: 'app-course-ebook-detail',
  templateUrl: './course-ebook.component.html',
  styleUrls: ['./course-ebook.component.scss']
})

export class CourseEbookComponent implements OnInit {

  chapterListData: any = [];
  couserId: string = "";
  star: any = [{ "no": 1, "fill": "no" }, { "no": 2, "fill": "no" }, { "no": 3, "fill": "no" }, { "no": 4, "fill": "no" }, { "no": 5, "fill": "no" }];
  user: any = {};
  paymentType: string = "payNow";
  etextBook = false;
  textbooksolution = false;
  revisiontest = false;
  purchasedVersion = 'trial';
  

  @Input()
    set CourseEbookDetail(parentData: any) {
        this.chapterListData = parentData.chapterListData;  
        this.couserId = parentData.couserId;
    }
    
  constructor(
    private _apiService: ApiService,
    private router: Router,
    private _broadcastService: BroadcastService,
    private toasterService: ToasterService,
  ) {
    
  }

  ngOnInit() {
   
      if (this.chapterListData.length > 0) {
        let { purchaseInfo } = this.chapterListData[0]
        let { paidType } = purchaseInfo
        this.purchasedVersion = paidType
        if (this.chapterListData[0].languageOption.length === 1) {
          this.mode = this.chapterListData[0].languageOption[0]
        }
        callToolTip();
        var val = Math.round(parseFloat(this.toFixed(this.chapterListData[0].userReviews.averageRating)));
        this.star.forEach(element => {
          if (element.no <= val)
            element.fill = "fill-color";
        });
        this.etextBook = this.chapterListData[0].etextBook;
        this.textbooksolution = this.chapterListData[0].textbooksolution;
        this.revisiontest = this.chapterListData[0].revisiontest;
        if(JSON.stringify(this.chapterListData[0].purchaseInfo) !== '{}' && this.chapterListData[0].purchaseInfo.isPurchased){
          this.router.navigateByUrl('mycoursedetails/course/' + this.chapterListData[0].purchaseInfo.purchaseDetails);
        }
      } else {
        this.chapterListData = [];
      }
  }
  public gotoCourse(purchaseId) {
    this.router.navigateByUrl('mycoursedetails/course/' + purchaseId);
  }
  public purchaseNow(type, paidType, payType) {
    localStorage.setItem("src", "web");
    this._broadcastService.broadcastLoader(true);
    this.router.navigateByUrl('pay?utm_source=' + this.couserId + "&utm_type=" + type + "&utm_purchase=" + paidType + "&type=" + payType);
  }
  public starRating(star, idx) {
    idx = idx + 1;
    var val = Math.round(parseFloat(this.toFixed(star / 10)));
    if (idx <= val)
      return "fill-color";
    else
      return "no";
  }
  public dateformatString(date) {
    return Utils.checkLanguageAvaliable(date);
  }
  public toFixed(number) {
    return Utils.toFixed(number);
  }
  public EnableLanguage(lang) {
    return Utils.checkDescriptionContent(lang, this.mode);
  }
  ngAfterViewInit(){
    $(".view-more-content").slideUp();

  }
  viewmore(){
    $(".view-more-content").slideToggle();
    $(".toggle-btn-view-all").toggleClass("view-less-text");
    $(".circle-tick-list").toggleClass("before-content");
  }
  public viewMode(data) {
    return Utils.showInAvailbleLanguage(data, this.mode);
  }
  mode: any = 'en';
  public change() {
    this.mode = this.mode == 'en' ? 'mal' : 'en';
    callToolTip();
  }
  public changeLanguage(data) {
    try {
      if (this.mode == 'mal' && typeof (data.mal) != 'undefined') {
        if (data.mal.trim().length > 0) {
          return data.mal;
        }
      }
      return data.en;
    } catch (e) {

    }

    return data.en;
  }
  public checkImageUrl(url) {
    return Utils.checkImageAvailable(url);
  }
  public checkPayment(price) {
    if (typeof (price.oneyr) != "undefined" && parseInt(price.oneyr) > 0) {
      return true;
    }
    if (typeof (price.twoyr) != "undefined" && parseInt(price.twoyr) > 0) {
      return true;
    }
    if (typeof (price.lifeTime) != "undefined" && parseInt(price.lifeTime) > 0) {
      return true;
    }
    if (typeof (price.freeTrial) != "undefined" && parseInt(price.freeTrial) > 0) {
      return true;
    }
    return false;
  }
  public checkSinglePayment(price) {
    if (typeof (price) != "undefined" && parseInt(price) >= 0) {
      return true;
    }
    return false;
  }
  redeemCode: string = "";
  public redeemNow(name) {

    var purchaseData = {};

    this._broadcastService.broadcastLoader(true);
    purchaseData = {
      "isredeemCode": true,
      "redeemCode": this.redeemCode,
    }
    this._apiService.post(GlobalConfig.Urls.user_purchase_subscription, purchaseData).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        this.redeemCode = "";
        if (val.message == "Access Code Not Valid.") {
          this.toasterService.clear(); this.toasterService.pop("error", 'pay', val.message);
        } else {
          this.toasterService.pop("success", 'Pay', val.message);
          setTimeout(function () {
            if (typeof (name) == "undefined" || name == "")
              name = "sub"
            window.location.href = "/success/redeem/subscription/" + name;
          }, 1000);
        }
      }
    });
  }
  public purchaseNowRazor(name, chapterId, amount) {
    var payment = (parseInt(amount) | 0) * 100;
    localStorage.setItem("chapterId", chapterId);
    localStorage.setItem("payName", name);
    var options = {
      "key": environment.paymentKey, // Dev rzp_test_YTJnDZw7enK4S8 //Live rzp_test_YTJnDZw7enK4S8
      "amount": payment,    // 2000 paise = INR 20
      "name": "Eksalar",
      "order_id": "",
      "currency": "INR",
      "description": name,
      "image": "/assets/img/logo.png",
      "handler": function (response) {
        $('#preloader').removeClass('hideLoading');
        $('#status').removeClass('hideLoading');
        $('#preloader').addClass('showLoading');
        $('#status').addClass('showLoading');
        $('#referanceNumber').html(response.razorpay_payment_id);
        $('#checkoutContainer').hide();
        var chapterId = localStorage.getItem("chapterId");

        var purchaseData = {};


        purchaseData = {
          "chapterId": chapterId,
          "redeemCode": "",
          "isredeemCode": false, // fullversion
          "paymentId": response.razorpay_payment_id,

        }

        var authorizationToken = localStorage.getItem('accesstoken');

        $.ajax({
          type: "POST",
          beforeSend: function (request) {
            request.setRequestHeader("x-auth", authorizationToken);
            request.setRequestHeader("Access-Control-Allow-Origin", "*");
          },
          url: localStorage.getItem('url') + "user/checkout/subscription",
          contentType: "application/json",
          dataType: 'json',
          data: JSON.stringify(purchaseData),
          success: function (data) {
            $('#preloader').addClass('hideLoading');
            $('#status').addClass('hideLoading');
            $('#preloader').removeClass('showLoading');
            $('#status').removeClass('showLoading');
            if (data.status == 200) {
              var namePay = localStorage.getItem("payName");
              if (typeof (namePay) == "undefined" || namePay == "")
                namePay = "sub"
              window.location.href = "/success/payment/subscription/" + namePay + "/?rn=" + response.razorpay_payment_id + "&pr=" + (payment / 100);
            } else {
              $('#paymentDetails').text(data.message);
              $('#payAlert').addClass('popup');

            }
          },
          error: function (err) {

            var result: any;
            $('#preloader').addClass('hideLoading');
            $('#status').addClass('hideLoading');
            $('#preloader').removeClass('showLoading');
            $('#status').removeClass('showLoading');
            result = JSON.stringify(err);
            result = JSON.parse(result);
            $('#paymentDetails').text(result.responseJSON.message);
            $('#payAlert').addClass('popup');
          }

        });

      },
      "prefill": {
        "contact": this.user.phone,
        "name": this.user.firstName,
        "email": this.user.email
      },
      "theme": {
        "color": "#0056b3"
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.open();
  }
  public removeClass() {
    $('#payAlert').removeClass('popup');
  }
  public getProfileDetails(chapterName, chapterId, amount) {
    this._broadcastService.broadcastLoader(true);
    this._apiService.get(GlobalConfig.Urls.public_profile).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        this.user = val.data;
        this.purchaseNowRazor(chapterName, chapterId, amount);

      } else {
        this.toasterService.clear(); this.toasterService.pop("error", 'pay', val.message);
      }
    });

  }
  public gotoChapter(chapterId) {
    if (this.chapterListData[0].purchaseInfo) {
      this.router.navigateByUrl('/mychapterdetails/' + this.chapterListData[0].purchaseInfo.purchaseDetails + "/" + chapterId);
    }

  }
  //Free Course
  public purchaseNowFreePackage() {
    var purchaseData = {
      "courseId": this.couserId,
      "materialType": "course",
      "paidType": 'trial',
      "paymentID": "",
      "validity": 1
    }
    this.userPurchase(purchaseData);
  }
  public userPurchase(purchaseData) {
    var name = this.chapterListData[0].name;
    this._broadcastService.broadcastLoader(true);
    this._apiService.post(GlobalConfig.Urls.user_purchase, purchaseData).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        this.router.navigateByUrl('/success/redeem/course/' + name);
      }

    });
  }

}

