import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
  iconSidenav = <HTMLVideoElement>document.getElementById('iconSidenav');
  sidenav = <HTMLVideoElement>document.getElementById('sidenav-main');
  body = document.getElementsByTagName('body')[0];
  className = 'g-sidenav-pinned';
  
  
  constructor() { }

  ngOnInit(): void {
  


  }

  ngOnChanges(){
    if(this.iconNavbarSidenav) {
      this.iconNavbarSidenav.addEventListener("click", this.toggleSidenav);
    }
    
    if (this.iconSidenav) {
      this.iconSidenav.addEventListener("click", this.toggleSidenav);
    }


    this.iconSidenav.addEventListener("click", function(){


      
    });

   
  

    
  }
  
  
  toggleSidenav() {
    console.log('sidenav');
    if (this.body.classList.contains(this.className)) {
      this.body.classList.remove(this.className);
      setTimeout(()=> {
        this.sidenav.classList.remove('bg-white');
      }, 100);
      this.sidenav.classList.remove('bg-transparent');
  
    } else {
      this.body.classList.add(this.className);
      this.sidenav.classList.add('bg-white');
      this.sidenav.classList.remove('bg-transparent');
      this.iconSidenav.classList.remove('d-none');
    }
  }
}
