import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    $(".module .trigger ul.locations").css("display", "none");    
    window.scrollTo(0, 0);
    $(document).ready(function() {
      $(".module .trigger span").on('click',function(){
        $(this).siblings("ul.locations").toggleClass("filter-show");
      });
      $( "body" ).on( "click",".trigger li", function() {
        $(this).parent('ul.locations').removeClass("filter-show");
      });
    });
  }
}
