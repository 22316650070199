import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";  
import { Observable, forkJoin } from "rxjs";  
import { DataLoaderService } from '@app/core';

@Injectable()  
export class DataLoadResolve implements Resolve<any> {
    constructor(private  dataloadservice: DataLoaderService){}
     resolve(activeRoute:ActivatedRouteSnapshot, 
          state:RouterStateSnapshot,
         ): Observable<any> {
              let role = this.dataloadservice.getUserRole();
              let project = this.dataloadservice.getProjects();
              let pentesters = this.dataloadservice.getPentesters();
              let user = this.dataloadservice.getUsers();
              return forkJoin([role,project,pentesters,user]);
             
    }
  }