import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';

@Component({
    selector: 'app-course-list',
    templateUrl: './course-list.component.html'
  })
export class CourseListComponent implements OnInit {
    courseListData: any = [];
    isInitialLoading:boolean=false;
    isUserLogin: boolean;
    subjects: any = [];
    levelList: any = [];
    price: string = "all";
    subject: string = "all";
    level: string = "all";
    selectedSubject: string = "All Subject";
    selectedLavel: string ="All Level";

    @Input()
    set CourseListDetail(parentData: any) {
        this.courseListData = parentData.courseListData;  
        this.isInitialLoading = parentData.isInitialLoading;
    }

    constructor(
      private _apiService: ApiService,
      private _broadcastService: BroadcastService,
      private router: Router,
      private activeRoute: ActivatedRoute
    ){
      
    }
    ngOnInit() {
      this._broadcastService.broadcastLoader(true);
      this.levelList = [
        { name: "+1", value: "plusone" },
        { name: "+2", value: "plustwo" },
        //{ name: "Degree", value: "degree" },
        //{ name: "Beginner", value: "beginner" },
        //{ name: "Intermediate", value: "intermediate" },
        //{ name: "Advanced", value: "advanced" },
      ];

      this.activeRoute.queryParams.subscribe(queryParams => {
        const parmas = { ...queryParams.keys, ...queryParams };
  
        // if (parmas['free']) {
        //   this.price = parmas['free'];
        // }
        // else {
        //   this.price = "all";
        // }
        if (parmas['subject']) {
          this.subject = parmas['subject'];
          this.filterSubject(parmas['subject'])
          console.log(this.subject);
        }
        else {
          this.subject = "all";
          this.filterSubject(parmas['subject']);
        }
        if (parmas['level']) {
          this.level = parmas['level'];
        }
        else {
          this.level = "all";
        }

        this.getCourseList();
      });
      if (localStorage.getItem('accesstoken') == null) {
        this.isUserLogin = false;  
      } else {
        this.isUserLogin = true;
      }
      this._apiService.get(GlobalConfig.Urls.public_subject).subscribe((val) => {
        if (val.status == 200) {
            this.subjects = val.data;
            this._broadcastService.broadcastLoader(false);
            this.filterSubject(this.subject);
            this.filterLavel(this.level);
        }
    });
    }
    public getCourseList() {
      this._broadcastService.broadcastLoader(true);
      this.filterSubject(this.subject);
      this.filterLavel(this.level);
      this._apiService.get(GlobalConfig.Urls.public_course + "?subject=" + this.subject + "&level=" + this.level).subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        this.isInitialLoading = true;
        if (val.status == 200) {
          this.courseListData = val.data;
          this.historyPush();
        }
      });
  }
  public filterSubject(id) {   
    var name = "All Subject";
    this.subjects.forEach(element => {
      if (element.id == id) {
        name = element.name;
      }
    });
    this.selectedSubject = name;
  }  
  public filterLavel(id) {
    var name = "All Level";
    this.levelList.forEach(element => {
      if (element.value == id) {
        name = element.name;
      }
    });
    this.selectedLavel = name;
  }
  historyPush() {
      let locat = location.href;
      if (location.href.indexOf("?") > -1)
        locat = location.href.substring(0, location.href.indexOf("?"));
      let queryString = [];
      if (this.price) {
        queryString.push("free=" + this.price);
      }
      if (this.subject) {
        queryString.push("subject=" + this.subject);
      }
      if (this.level) {
        queryString.push("level=" + this.level);
      }
      if (queryString.length > 0) {
        locat = locat + "?" + queryString.join("&");
      }
      history.replaceState(null, null, location.origin);
      history.pushState(null, null, locat);
    }
    counter(i: number) {
      return new Array(i);
    }
    public checkImageUrl(url) {
      return Utils.checkImageAvailable(url);
    }
}