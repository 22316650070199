import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-footer',
  templateUrl: './learning-footer.component.html',
  styleUrls: ['./learning-footer.component.scss']
})
export class LearningFooterComponent implements OnInit {
  constructor() { }
  today: Date = new Date();
  year: any = (new Date()).getFullYear();
  ngOnInit() {


    
   }
}
