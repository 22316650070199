import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { GlobalConfig } from '@env/globalconfig';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  //styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
  iconSidenav = <HTMLVideoElement>document.getElementById('iconSidenav');
  sidenav = <HTMLVideoElement>document.getElementById('sidenav-main');
  body = document.getElementsByTagName('body')[0];
  className = 'g-sidenav-pinned';
  
  
  constructor(
    private _apiService: ApiService,private _broadcastService: BroadcastService,) {

   }
  

  ngOnInit(): void {
   
    $( ".menu-icon" ).on( "click", function(){

      let iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
  let iconSidenav = <HTMLVideoElement>document.getElementById('iconSidenav');
  let sidenav = <HTMLVideoElement>document.getElementById('sidenav-main');
  let body = document.getElementsByTagName('body')[0];
  let className = 'g-sidenav-pinned';

     

      body.classList.remove(className);
    setTimeout(()=> {
      sidenav.classList.remove('bg-white');
      $( "#overlay" ).remove();

    }, 100);
    sidenav.classList.remove('bg-transparent');


  
    } );
 

  }

 

  ngOnChanges(){
    if(this.iconNavbarSidenav) {
      this.iconNavbarSidenav.addEventListener("click", this.toggleSidenav);
    }
    
    if (this.iconSidenav) {
      this.iconSidenav.addEventListener("click", this.toggleSidenav);
    }
  }
  
  
  toggleSidenav() {
   
    if (this.body.classList.contains(this.className)) {
      this.body.classList.remove(this.className);
      setTimeout(()=> {
        this.sidenav.classList.remove('bg-white');
      }, 100);
      this.sidenav.classList.remove('bg-transparent');
      
  
    } else {
      this.body.classList.add(this.className);
      this.sidenav.classList.add('bg-white');
      this.sidenav.classList.remove('bg-transparent');
      this.iconSidenav.classList.remove('d-none');
    }
  }
  public logout() {
    this._broadcastService.broadcastLoader(true);
    this._apiService.get(GlobalConfig.Urls.login).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        localStorage.clear();
        window.location.href = "/";
        //this.router.navigateByUrl('/');
      }
    });
  }
}
