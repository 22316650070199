import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
declare function readyFunction(type): any;

@Component({
    selector: 'app-testbook-list',
    templateUrl: './testbook-solutions.component.html'
  })
  export class TestEbookListComponent implements OnInit {
    componentVariable: any = [];
    courseListData: any = [];
    totalData: any = [];
    isInitialLoading: boolean = false;
    subjects: any = [];
    levelList: any = [];
    price: string = "all";
    subject: string = "all";
    level: string = "all";
    selectedSubject: string = "All Subject";
    selectedLavel: string ="All ";
    isUserLogin: boolean;
    
    constructor(
        private _apiService: ApiService,
        private _broadcastService: BroadcastService,
        private router: Router,
        private activeRoute: ActivatedRoute
    ){}
    ngOnInit() {
      this.levelList = [
        { name: "Text Book Solutions", value: "Text Book Solutions" },
        { name: "EBooks", value: "EBooks" }
      ];
      if (localStorage.getItem('accesstoken') == null) {
        this.isUserLogin = false;  
      } else {
        this.isUserLogin = true;
      }
      this.levelList = [
        { name: "+1", value: "plusone" },
        { name: "+2", value: "plustwo" },
        //{ name: "Degree", value: "degree" },
        //{ name: "Beginner", value: "beginner" },
        //{ name: "Intermediate", value: "intermediate" },
        //{ name: "Advanced", value: "advanced" },
      ];

      this.activeRoute.queryParams.subscribe(queryParams => {
        const parmas = { ...queryParams.keys, ...queryParams };
  
        // if (parmas['free']) {
        //   this.price = parmas['free'];
        // }
        // else {
        //   this.price = "all";
        // }
        if (parmas['subject']) {
          this.subject = parmas['subject'];
          this.filterSubject(parmas['subject'])
          console.log(this.subject);
        }
        else {
          this.subject = "all";
          this.filterSubject(parmas['subject']);
        }
        if (parmas['level']) {
          this.level = parmas['level'];
        }
        else {
          this.level = "all";
        }

        this.getCourseList();
      });
      this._apiService.get(GlobalConfig.Urls.public_subject).subscribe((val) => {
        if (val.status == 200) {
            this.subjects = val.data;
            this._broadcastService.broadcastLoader(false);
            this.filterSubject(this.subject);
            this.filterLavel(this.level);
        }
      });
    }
    
    public getCourseList() {
        this._broadcastService.broadcastLoader(true);
        this.filterSubject(this.subject);
        this.filterLavel(this.level);
        this._apiService.get(GlobalConfig.Urls.public_course + "?type=testbooksolution"+ "&subject=" + this.subject + "&level=" + this.level).subscribe((val) => {
          this._broadcastService.broadcastLoader(false);
          this.isInitialLoading = true;
          if (val.status == 200) {
            this.courseListData = val.data;
            this.totalData = val.data;
            this.historyPush();
          }
        });
    }
    public filterData(){
      console.log(this.level);
      let sortedData=[];
      console.log(this.courseListData);
      this.totalData.forEach(element => {
        if (element.ebook_category == this.level) {
          sortedData.push(element);
        }
      });
      if(this.level =='all'){
        this.courseListData = this.totalData;
      }else{
        this.courseListData = sortedData;
      }
    }
    counter(i: number) {
        return new Array(i);
    }
    public checkImageUrl(url) {
        return Utils.checkImageAvailable(url);
    }
    public filterSubject(id) {   
      var name = "All Subject";
      this.subjects.forEach(element => {
        if (element.id == id) {
          name = element.name;
        }
      });
      this.selectedSubject = name;
    }  
    public filterLavel(id) {
      var name = "All Level";
      this.levelList.forEach(element => {
        if (element.value == id) {
          name = element.name;
        }
      });
      this.selectedLavel = name;
    }

    public courseDetails(course:any){

     

      this._apiService.get(GlobalConfig.Urls.public_chapter + course._id).subscribe((val) => {
      
        console.log(val.data[0].name);
      if(JSON.stringify(val.data[0].purchaseInfo) !== '{}' && val.data[0].purchaseInfo.isPurchased){
        this.router.navigateByUrl('mycoursedetails/course/' + val.data[0].purchaseInfo.purchaseDetails);
      }else{
        
        this.router.navigateByUrl('course-detail-ebook-dashboard/' + val.data[0].name +'/'+val.data[0]._id);

      }

      
      });
    }
    historyPush() {
      let locat = location.href;
      if (location.href.indexOf("?") > -1)
        locat = location.href.substring(0, location.href.indexOf("?"));
      let queryString = [];
      if (this.price) {
        queryString.push("free=" + this.price);
      }
      if (this.subject) {
        queryString.push("subject=" + this.subject);
      }
      if (this.level) {
        queryString.push("level=" + this.level);
      }
      if (queryString.length > 0) {
        locat = locat + "?" + queryString.join("&");
      }
      history.replaceState(null, null, location.origin);
      history.pushState(null, null, locat);
    }

  }