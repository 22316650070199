
export const GlobalConfig = {

  Urls: {
    login: "auth/login",
    phone_login: "auth/sendValidation",
    validateOTP: "auth/validateOTP",
    userRegistration: "auth/userRegistration",
    saveError: "public/error-log",
    checkmail: "user/checkemail/",
    generateOTP: "user/generateOTP",
    verifyOTP: "user/verifyOTP",
    updateUserDetails: "user/updateUserDetails",
    registration: "user/register?login=true",
    forgot_password: "user/userforgot-password/",
    //forgot_password: "user/forgot-password/", old
    update_password: "user/user-update-forgot-password",
    // update_password: "user/update-forgot-password",//old
    change_password: "user/change-password",
    public_course: "public/list-course",
    public_course_ebook: "public/list-course/etexts",
    public_package: "public/list-sell-ind-packages",
    public_mocktest: "quiz/mockTest/list",
    public_solved_questions: "quiz/solved-questions-paper/list",
    public_subject: "public/subjects",
    public_courses: "public/courses",
    public_package_details: "public/view-sell-ind-package",
    public_chapter: "public/list-chapters/",
    public_chapter_bulk: "public/list-chapters_bulk/",
    my_course_chapter_details: "user/myCourseChapterdetails",
    public_list_quiz: "public/list-quizes/",
    public_profile: "user/get-profile-details",
    user_courses: "user/mycourses",
    user_courses_details: "user/mycoursedetails",
    user_get_transaction_list: "user/get-transaction-list",
    user_purchase: "user/purchase",
    user_purchase_bulk: "user/purchaseSubscription",
    user_coupon: "user/getCouponDetails",
    user_purchase_subscription: "user/checkout/subscription",
    getSubscriptionCharges: "user/get-subsctiption-charges",
    update_profile_details: "user/update-profile-details",
    get_question: "user/get-question",
    retry: "quiz/retry",
    mysummary: "quiz/mysummary",
    verifyanswer: "quiz/questions/verifyanswer",
    start_discussion: "discussion/start-discussion",
    listalldiscussion: "discussion/list-all-discussion",
    my_purchased_course_discussions: "discussion/my-purchased-course-discussions",
    delete_discussion: "discussion/delete-discussion",
    get_single_discussion: "discussion/get-single-discussion",
    update_user_status: "discussion/update-user-status",
    answer_discussion: "discussion/answer-discussion",
    update_discussion: "discussion/update-discussion",
    update_device: "auth/device",
    previousQuestionList: "previousQuestions",
    previousQuestionDownload: "previousQuestions/",
    view_sell_ind_package: "public/view-sell-ind-package",
    save_feedback: "quiz/review",
    contact: "public/contact",
    featured: "public/featured",
    public_search: "public/search",
    store: "store",
    previewChapter: "chapter/preview",
    previewQuestion: "quiz/questions/preview",
    ourSchools: "public/our-schools",
    upload: "upload",
    offline_book: "course/offline-books",
    latest_mocktest: "quiz/mockTest/latest",
    public_book : "course/offline-books/",
    user_orders : "user/orders",
    public_solvedQuestions: "public/list-solved-questions",
    public_solvedQuestionchapter: "public/list-solved-question-chapters/",
    get_solved_question: "public/get-solved-question/",
    get_subject_studied: "user/get-subject-study",
    get_studied_report: "user/get-study-detail/",
    getSettings: "setting/getSettingsPublic/",
  }
};

