import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  public getToken(): string {
    return localStorage.getItem('accesstoken');
  }
  
  public getRefreshToken(): string{
    return localStorage.getItem('refreshtoken');
  }

  public isAuthenticated() {
    return true;
  }
}
