import { Component, OnInit,HostListener } from '@angular/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent  {


  public showLoader: boolean;
  /**Contructor */
  constructor(private _broadcastService: BroadcastService) {
    this.showLoader = false;
    this._broadcastService.loaderSource.subscribe((showLoader) => {
      if (showLoader) {
        $('.popover').remove();
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }

    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

}
