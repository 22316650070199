/**
 * name
 */
export class Utils {
    static checkImageAvailable(val: string) {
        if (typeof (val) == "undefined" || val == null || val === "null" || (val && val.trim() == ""))
        {
            return "/assets/img/placeholder.png";
        }else{

            let isS3url = val.indexOf("s3.ap-south-1.amazonaws.com/static.eksalar.contents");

            if(isS3url>0){
                let urls3 = val.replace("s3.ap-south-1.amazonaws.com/static.eksalar.contents", "d1jouk3srpd7b5.cloudfront.net");
                val = urls3;
            }

            
            return val;
        }
    }
    static avatarStatic(val: string) {
        if (!val || val === "undefined" || typeof (val) == "undefined" || val == null || val.trim() == "")
            return "/assets/img/avatar.png";
        else
            return val;
    }
    static dateformatString(date) {
        var d = new Date(date);
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
      }
      static toFixed(number) {
        return (parseFloat(number)|0).toFixed(2);
      }
    static checkLanguageAvaliable(lang) {
        if (typeof (lang) == "undefined") {
            return false;
        } try {
            var div = document.createElement('div');
            div.innerHTML = lang["mal"].trim();
            if (div.innerText.trim().length > 0) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }
    static checkDescription(desc) {
        if (typeof (desc) == "undefined" || desc == null) {
            return false;
        } try {
            var div = document.createElement('div');
            div.innerHTML = desc["en"].trim();
            if (div.innerText.trim().length > 0) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }
    static GetAvaliableLanguageName(data) {

        var lang = "";
        if (typeof (data) == "undefined" || data == null) {
            return lang;
        } try {
            if (typeof (data["en"]) == "undefined" || data["en"] == null) {
                return lang;
            }
            var div = document.createElement('div');
            div.innerHTML = data["en"].trim();
            if (div.innerText.trim().length > 0) {
                lang = "English";
            } else {
                return lang;
            }
            var div = document.createElement('div');
            div.innerHTML = data["mal"].trim();
            if (div.innerText.trim().length > 0) {
                lang = lang + ",Malayalam";
            } else {
                return lang;
            }
            return lang;
        } catch (err) {
            return lang;
        }
    }
    static checkDescriptionContent(desc, mode) {

        if (typeof (desc) == "undefined" || desc == null) {
            return false;
        } try {
            if (typeof (desc[mode]) == "undefined" || desc[mode] == null) {
                return false;
            }
            var div = document.createElement('div');
            div.innerHTML = desc[mode].trim();
            if (div.innerText.trim().length > 0) {
                return true;
            } else {
                return false;
            }

        } catch (err) {
            return false;
        }
    }
    static showInAvailbleLanguage(desc, mode) {
        if (typeof (desc) == "undefined" || desc == null) {
            return '';
        }
        try {

            // get the other language
            let altLang = "en"
            if(mode === 'en'){
                altLang = 'mal'
            }

            //check data exists in requested language
            let dataInAvailble = ''
            if(desc[mode] && desc[mode] !== ""){
                dataInAvailble = desc[mode]
                return dataInAvailble
            }
            // below commented code helps to show content in any of the language available
            /* if(desc[altLang] && desc[altLang] !== ""){
                dataInAvailble = desc[altLang]
                return dataInAvailble
            }
            if(dataInAvailble === ''){
                dataInAvailble = desc[mode] === '' ?  desc[altLang] :  desc[mode]
                return dataInAvailble
            } */

        } catch (err) {
            return '';
        }
    }
    static getOptionLetter(idx) {
        var letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M ', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        return letter[idx];
    }
}
