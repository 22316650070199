import { Injectable } from '@angular/core';
import { ApiService } from './api.service'

@Injectable()

export class DataLoaderService {

    constructor(private _apiService: ApiService ) {}
  
    public getUserRole() : any
    {
        return this._apiService.get('appRoles');
    }

    public getCompany() : any
    {
        return this._apiService.get('company/list');
    }
    
    public getPointOfContact()
    {
        return this._apiService.get('user/poc');
    }

    public getPentesters()
    {
        return this._apiService.get('user/myPentesters');
    }

    public getProjectStatus()
    {
        return this._apiService.get('project/status');
    }

    public getProjects()
    {
        return this._apiService.get('project/list');
    }

    public getUsers() : any
    {
        return this._apiService.get('user/list');
    }
}