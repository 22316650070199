import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
declare function readyFunction(type): any;

@Component({
    selector: 'app-offline-book-list',
    templateUrl: './offline-book-list.component.html'
  })
  export class offlineBookListComponent implements OnInit {
    componentVariable: any = [];
    courseListData: any = [];
    isInitialLoading: boolean = false;
    subjects: any = [];
    levelList: any = [];
    languageList: any = [];
    subject: string = "all";
    language: string = "all";
    level: string = "all";    
    isScrollLoading: boolean = false;
    pageNumber: any = 1;
    selectedSubject:string;
    selectedLanguage:string;
    selectedLavel:string;
    
    constructor(
        private _apiService: ApiService,
        private _broadcastService: BroadcastService,
        private router: Router
    ){}
    ngOnInit() {
        this.subjects = [
          {name: "Science", value: "science"},
          {name: "Humanities", value: "humanities"},
          {name: "Language", value: "language"},
          {name: "Commerce", value: "commerce"}];
        this.levelList = [
          { name: "+1", value: "plusone" },
          { name: "+2", value: "plustwo" },
          { name: "BA", value: "ba" },
          { name: "BBA", value: "bba" },
          { name: "BCOM", value: "bcom" },
          { name: "supplementary", value: "supplementary" },];
        this.languageList = [
          { name: "Malayalam", value: "ml" },
          { name: "English", value: "en" },
        ];
        this._broadcastService.broadcastLoader(true);       
        this.getCourseList(1);
        //this._apiService.get(GlobalConfig.Urls.public_subject).subscribe((val) => {
        //    if (val.status == 200) {
        //        this.subjects = val.data;
        //        this._broadcastService.broadcastLoader(false);
        //    }
        //});
    }
    
    public getCourseList(page: number) {
       // this._broadcastService.broadcastLoader(true);

        this.filterSubject(this.subject);
        this.filterLavel(this.level);
        this.filterLanguage(this.language);
      

        this.isScrollLoading = true;
        this._apiService.get(GlobalConfig.Urls.public_course+ "?dist=true&subject=" + this.subject + "&level=" + this.level + "&language=" + this.language + "&pagin=true&page=" + page).subscribe((val) => {
          this._broadcastService.broadcastLoader(false);
          this.isInitialLoading = true;
          
          this.isScrollLoading = false;

          if (val.status == 200) {
           // this.courseListData = val.data;

            if (page == 1) {
              window.scrollTo(0, 0);
              this.courseListData = [];
            }
            this.pageNumber = page;
            //console.log(val.data.courses);
            val.data.books.forEach(element => {
              this.courseListData.push(element);
            });

          }
        });
        this.historyPush();
    }
    counter(i: number) {
        return new Array(i);
    }
    public checkImageUrl(url) {
        return Utils.checkImageAvailable(url);
    }
    historyPush() {
        let locat = location.href;
        if (location.href.indexOf("?") > -1)
          locat = location.href.substring(0, location.href.indexOf("?"));
        let queryString = [];
        if (this.subject) {
          queryString.push("subject=" + this.subject);
        }
        if (this.level) {
          queryString.push("level=" + this.level);
        }
        if (this.language) {
          queryString.push("language=" + this.language);
        }
        if (queryString.length > 0) {
          locat = locat + "?" + queryString.join("&");
        }
        history.replaceState(null, null, location.origin);
        history.pushState(null, null, locat);
      }

      onScroll() {
        if (!this.isScrollLoading) {
          this.pageNumber = this.pageNumber + 1;
          console.log('scrolled!!' + this.pageNumber);
          this.getCourseList(this.pageNumber);
        }else{
          console.log('progress' );
        }
      }


      public filterSubject(id) {   
        var name = "All Subject";
        this.subjects.forEach(element => { 
          if (element.name.toLowerCase() == id) {
            name = element.name;
          }
        });       
        this.selectedSubject = name     
      }
    
      public filterLavel(id) {       
        var name = "All";
        this.levelList.forEach(element => {
          if (element.value == id) {
            name = element.name;
          }
        });       
        this.selectedLavel = name        
      } 

      public filterLanguage(id) {       
        var name = "All";
        this.languageList.forEach(element => {
          if (element.value == id) {
            name = element.name;
          }
        });       
        this.selectedLanguage = name        
      }  
      public purchaseNow(id, slug) {
        this._broadcastService.broadcastLoader(true);
         localStorage.setItem("src", "web");
         this.router.navigateByUrl('paybook?utm_source=' + id + '&utm_slug='+ slug);
      }
  }