import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
import { Title, Meta } from '@angular/platform-browser';
import { ThrowStmt } from '@angular/compiler';
declare function readyFunction(type): any;

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss']
})

export class ResourceListComponent implements OnInit {
  ListData: any = [];
  categories: string = "all";
  subject: string = "all";
  level: string = "all";
  url: string = "";
  subjects: any = [];
  isUserLogin: boolean = false;
  category: string = "all";
  src: any = "";
  isInitialLoading: boolean = false;
  isScrollLoading: boolean = false;
  categoryList: any = [];
  levelList: any = [];
  selectedSubject:string;
  selectedLavel:string;
  selectedCategory:string;
  constructor(
    private _apiService: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _broadcastService: BroadcastService,
    private titleService: Title,
    private metaTagService: Meta,
  ) {
  }
  ngOnInit() {
    this.categoryList = [
      { name: "Syllabus", value: "syllabus" },
      // { name: "Study notes", value: "study notes" },
      // { name: "Model Question Papers", value: "model question papers" },
      // { name: "Announcements", value: "announcements" },
      { name: "Others", value: "others" },
    ];
    this.levelList = [
      { name: "+1", value: "plusone" },
      { name: "+2", value: "plustwo" },
      //{ name: "Degree", value: "degree" },
      //{ name: "Beginner", value: "beginner" },
      //{ name: "Intermediate", value: "intermediate" },
      //{ name: "Advanced", value: "advanced" },
    ];
    if (localStorage.getItem('accesstoken') == null) {
      this.isUserLogin = false;
     // this.router.navigateByUrl('/login');
    } else {
      this.isUserLogin = true;

    }

    this.getResourceList(1);
    this._apiService.get(GlobalConfig.Urls.public_subject).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        this.subjects = val.data;
      }
    });
  }
  public bindLevel(name) {

    this.levelList.forEach(element => {
      if (element.value == name) {
        name = element.name;
      }
    });
    return name;
  }
  public bindCategory(name) {
    this.categoryList.forEach(element => {
      if (element.value == name) {
        name = element.name;
      }
    });
    if (name == "Model Question Papers")
      return "Model Question Paper";
    return name;
  }
  public getResourceList(page: number) {
    this.isScrollLoading = true;
    this.filterSubject(this.subject);
    this.filterLavel(this.level);
    this.filterCategory(this.category);
    //this._broadcastService.broadcastLoader(true);
    this._apiService.get(GlobalConfig.Urls.previousQuestionList + "?category=" + this.category + "&subject=" + this.subject + "&level=" + this.level + "&pagin=true&page=" + page).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      this.isInitialLoading = true;
      this.isScrollLoading = false;
      if (val.status == 200) {
        if (page == 1) {
          window.scrollTo(0, 0);
          this.ListData = [];
        }
        this.pageNumber = page;
        val.data.quizes.forEach(element => {
          this.ListData.push(element);
        });
        // var noOfPage = Math.ceil(val.data.total / val.data.limit);
        // var pages = [];
        // for (var i = 1; i <= noOfPage; i++) {
        //   pages.push(i);
        // }
        // this.pagination = {
        //   currentPage: page,
        //   pages: pages,
        //   totalPages: noOfPage
        // };
      }
    });

  }

  public download(id) {
    this._broadcastService.broadcastLoader(true);
    this._apiService.get(GlobalConfig.Urls.previousQuestionDownload + id + "/download").subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        //this.openPdfView(val.data.link);
        window.open(val.data.link, "_blank");
      }
    });

  }

  public openPdfView(src) {
    window.open(src, "_blank");
    // this.src =
    //   this.sanitizer.bypassSecurityTrustHtml(

    //     "<iframe src='" + src + "#toolbar=0' style='width: 100%;height: 100%;' " +
    //     "failed to load pdf" +
    //     "</iframe>");
    ;

  }
  //Pagination
  // pagination: any = {
  //   currentPage: 0,
  //   pages: [],
  //   totalPages: 0
  // };
  // public setPage(number) {
  //   this.getResourceList(number);
  // }
  pageNumber: any = 1;
  onScroll() {
    if (!this.isScrollLoading) {
      this.pageNumber = this.pageNumber + 1;
      console.log('scrolled!!' + this.pageNumber);
      this.getResourceList(this.pageNumber);
    }else{
      console.log('progress' );
    }
  }

  public filterSubject(id) {
   
    var name = "All Subject";
    this.subjects.forEach(element => {
      if (element.id == id) {
        name = element.name;
      }
    });

   
    this.selectedSubject = name
    
  }

  public filterLavel(id) {
   
    var name = "All";
    this.levelList.forEach(element => {
      if (element.value == id) {
        name = element.name;
      }
    });

   
    this.selectedLavel = name
    
  }

  public filterCategory(id) {
   
    var name = "All";
    this.categoryList.forEach(element => {
      if (element.value == id) {
        name = element.name;
      }
    });

   
    this.selectedCategory = name
    
  }

}
