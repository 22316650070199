import { Component, OnInit, EventEmitter, Input, Output,SimpleChanges } from '@angular/core';
import { ApiService } from '@app/core';
import { environment } from '@env/environment';
declare var Razorpay: any;
import { Router } from '@angular/router';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
import { ToasterService } from 'angular2-toaster';
declare function callToolTip(): any;

@Component({
  selector: 'app-solved-question-chapter',
  templateUrl: './solved-question-chapter.component.html',
  styleUrls: ['./solved-question-chapter.component.scss']
})

export class SolvedQuestionChapterComponent implements OnInit {

  chapterListData: any = [];
  couserId: string = "";
  star: any = [{ "no": 1, "fill": "no" }, { "no": 2, "fill": "no" }, { "no": 3, "fill": "no" }, { "no": 4, "fill": "no" }, { "no": 5, "fill": "no" }];
  user: any = {};
  paymentType: string = "payNow";
  etextBook = false
  purchasedVersion = 'trial'
  package: any = {};
  chapterId:string="";
  questionCourseId:string="";
  isUserLogin: boolean;

  @Input()
    set CourseEbookDetail(parentData: any) {
        this.chapterListData = parentData.chapterListData;  
        this.couserId = parentData.couserId;
        this.questionCourseId = parentData._id;
        
    }
    
  constructor(
    private _apiService: ApiService,
    private router: Router,
    private _broadcastService: BroadcastService,
    private toasterService: ToasterService,
  ) {
    
  }

  ngOnInit() {
    if (localStorage.getItem('accesstoken') == null) {
      this.isUserLogin = false;  
    } else {
      this.isUserLogin = true;
    }
      if (this.chapterListData.length > 0) {
        let { purchaseInfo } = this.chapterListData[0]
       // let { paidType } = purchaseInfo
       // this.purchasedVersion = paidType
        if (this.chapterListData[0].languageOption.length === 1) {
          this.mode = this.chapterListData[0].languageOption[0]
        }
        callToolTip();
        var val = Math.round(parseFloat(this.toFixed(this.chapterListData[0].userReviews.averageRating)));
        this.star.forEach(element => {
          if (element.no <= val)
            element.fill = "fill-color";
        });
        this.etextBook = this.chapterListData[0].etextBook;
        // if(JSON.stringify(this.chapterListData[0].purchaseInfo) !== '{}' && this.chapterListData[0].purchaseInfo.isPurchased){
        //   this.router.navigateByUrl('mycoursedetails/course/' + this.chapterListData[0].purchaseInfo.purchaseDetails);
        // }
      } else {
        this.chapterListData = [];
      }
  }
  public gotoCourse(purchaseId) {
    this.router.navigateByUrl('mycoursedetails/course/' + purchaseId);
  }
  public purchaseNow(type, paidType, payType) {
    localStorage.setItem("src", "web");
    this._broadcastService.broadcastLoader(true);
    this.router.navigateByUrl('pay?utm_source=' + this.couserId + "&utm_type=" + type + "&utm_purchase=" + paidType + "&type=" + payType);
  }
  public starRating(star, idx) {
    idx = idx + 1;
    var val = Math.round(parseFloat(this.toFixed(star / 10)));
    if (idx <= val)
      return "fill-color";
    else
      return "no";
  }
  public dateformatString(date) {
    return Utils.checkLanguageAvaliable(date);
  }
  public toFixed(number) {
    return Utils.toFixed(number);
  }
  public EnableLanguage(lang) {
    return Utils.checkDescriptionContent(lang, this.mode);
  }
  ngAfterViewInit(){
    $(".view-more-content").slideUp();

  }
  viewmore(){
    $(".view-more-content").slideToggle();
    $(".toggle-btn-view-all").toggleClass("view-less-text");
    $(".circle-tick-list").toggleClass("before-content");
  }
  public viewMode(data) {
    return Utils.showInAvailbleLanguage(data, this.mode);
  }
  mode: any = 'en';
  public change() {
    this.mode = this.mode == 'en' ? 'mal' : 'en';
    callToolTip();
  }
  public changeLanguage(data) {
    try {
      if (this.mode == 'mal' && typeof (data.mal) != 'undefined') {
        if (data.mal.trim().length > 0) {
          return data.mal;
        }
      }
      return data.en;
    } catch (e) {

    }

    return data.en;
  }
  public checkImageUrl(url) {
    return Utils.checkImageAvailable(url);
  }



 
  public assignPackageId(data,chId) {
    this.package = data;
    this.chapterId = chId;
    
    if(this.package.packageName){
      if(this.package.packageName.includes('Learning Objective')){
        this.package.description = {en: "", mal: ""}
      }
    }
    callToolTip();
  }
  public gotoQuestion() {
    if(this.isUserLogin){    
      this.router.navigateByUrl('/getsolvedquestion-dashboard/course/' + this.couserId + "/" + this.chapterId + "/" + this.package.packId);
    }else{    
      this.router.navigateByUrl('/getsolvedquestion/course/' + this.couserId + "/" + this.chapterId + "/" + this.package.packId);

    }
  }

  public changeLanguageExam(data) {
    try {
      if (this.mode == 'mal' && typeof (data.mal) != 'undefined') {
        if (data.mal.trim().length > 0) {
          return data.mal;
        }
      }

      return data.en;
    } catch (e) {

    }
    return typeof (data) == "undefined" ? "" : data.en;
  }  
  //Free Course
  public purchaseNowFreePackage() {
    var purchaseData = {
      "courseId": this.couserId,
      "materialType": "course",
      "paidType": 'trial',
      "paymentID": "",
      "validity": 1
    }
    this.userPurchase(purchaseData);
  }
  public userPurchase(purchaseData) {
    var slug = this.chapterListData[0].slug;
    this._broadcastService.broadcastLoader(true);
    this._apiService.post(GlobalConfig.Urls.user_purchase, purchaseData).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        window.location.href = 'solved-question-detail-dashboard/'+slug+'/'+ this.couserId;
        //this.router.navigateByUrl('solved-question-detail-dashboard/'+slug+'/'+ this.couserId);
      }
    });
  }
}

