import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class GlobalService {
    public buyNowEvent$ = new BehaviorSubject<boolean>(false);
    get getBuyNowEvent() {
        return this.buyNowEvent$.value;
    }
    public setBuyNowEvent(val: boolean) {
        this.buyNowEvent$.next(val);
    }
}