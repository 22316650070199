import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare function callMenuFunction(): any;
import { environment } from '@env/environment';
import { ApiService } from '@app/core';
import { Utils } from '@app/core/utility/Utils';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { GlobalConfig } from '@env/globalconfig';
declare function readyFunction(type): any;
declare var $: any;


@Component({
  selector: 'app-learning-header',
  templateUrl: './learning-header.component.html',
  styleUrls: ['./learning-header.component.scss']
})
export class LearningHeaderComponent implements OnInit {
  name: any;
  email: any;
  role: any;
  isHomePage: boolean;
  isShowAutoComplete: boolean;
  isUserLogin: boolean;
  isStudent: boolean;
  searchQuery: string = "";
  image: string = "";
  searchData: any = [];
  public lottieConfig: Object;
  public lottieConfigmock: Object;
  public lottieConfigbook: Object;
  public lottieConfigpaper: Object;
  public lottieConfigsolution: Object;
  

  constructor(
    private router: Router,
    private _apiService: ApiService,
    private _broadcastService: BroadcastService,
  ) { 

    this.lottieConfig = {
      path: 'https://assets2.lottiefiles.com/packages/lf20_DMgKk1.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
  };

  this.lottieConfigmock = {
    path: 'https://assets10.lottiefiles.com/packages/lf20_v4t3yi.json',
    renderer: 'svg',
    autoplay: true,
    loop: true
    };

    this.lottieConfigbook = {
      path: 'https://assets3.lottiefiles.com/datafiles/5FFwoVNq8EcKMH4/data.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
      };
      this.lottieConfigpaper = {
        path: 'https://assets2.lottiefiles.com/packages/lf20_qkyby4lm.json',
        renderer: 'svg',
        autoplay: true,
        loop: true
        };
        this.lottieConfigsolution = {
          path: 'https://assets10.lottiefiles.com/packages/lf20_szrbrL.json',
          renderer: 'svg',
          autoplay: true,
          loop: true
          };   

  }
  ngOnInit() {
    var url = this.router.url;
    localStorage.setItem('url', environment.apiUrl);
    if (localStorage.getItem('accesstoken') == null) {
      this.isUserLogin = false;
      this.isStudent = false;

    } else {
      this.isUserLogin = true;
      this.isStudent = true;
    }

    if (url == "/" || url == "/home")
      this.isHomePage = true;
    else {
      this.isHomePage = false;
    }
    this.name = localStorage.getItem('username');
    this.email = localStorage.getItem('email');
    this.role = localStorage.getItem('role');
    this.image = localStorage.getItem('image');
    setTimeout(function () {
      readyFunction('menu');
    }, 100);

    $('body').removeClass('bg-gray-200');

    $('body').addClass('common-pages');

  }
  public searchCourseDetails(searchQuery) {
    this.searchQuery = searchQuery;
    if (this.searchQuery.length > 3) {
      var data = {
        "item": this.searchQuery
      };
      let response = this._apiService.post(GlobalConfig.Urls.public_search, data);
      response.subscribe((val) => {
        if (val.status == '200') {
          this.searchData = val.data;
          if (val.data.length > 0) {
            this.isShowAutoComplete = true;
          } else {
            this.isShowAutoComplete = false;
          }

        }
      });
    } else {
      this.searchData = [];
      this.isShowAutoComplete = false;
    }
  }
  public logout() {
    this._broadcastService.broadcastLoader(true);
    this._apiService.get(GlobalConfig.Urls.login).subscribe((val) => {
      this._broadcastService.broadcastLoader(false);
      if (val.status == 200) {
        localStorage.clear();
        window.location.href = "/";
        //this.router.navigateByUrl('/');
      }
    });
  }

  public checkImageAvailable() {
    return Utils.avatarStatic(this.image);
  }

  searchList(data){

    let url = "/course-details/preview/"+data._id;

    window.location.href=url;

  }

  searchTestList(data){

    let url = "/tests-dashboard/preview/"+data._id;

    window.location.href=url;

  }


}
