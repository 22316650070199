import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule }  from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@app/shared';
import { APP_CONFIG, AppConfig } from '@app/config';
import { CoreModule } from '@app/core';

import { AppComponent } from './app.component';
import { PageLoaderComponent } from './shared/components/page-loader/page-loader.component';
import { BroadcastService } from '@app/core/services/broadcast.service';
import {StarRatingModule} from 'angular-star-rating';
import { ErrorService } from './core/services/error.service';
//import { DashboardComponent } from './v2/dashboard/dashboard.component';
import { FooterComponent } from './v2/footer/footer.component';
import { SidenavComponent } from './v2/sidenav/sidenav.component';
import { HeaderComponent } from './v2/header/header.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import {NavService} from '@app/core/services/profile.service';
import {NgDynamicBreadcrumbModule} from 'ng-dynamic-breadcrumb';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [AppComponent,PageLoaderComponent,FooterComponent,HeaderComponent,
    SidenavComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    StarRatingModule.forRoot(),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    ToasterModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    LottieAnimationViewModule.forRoot(),
    NgDynamicBreadcrumbModule,
    PdfViewerModule
  ],
  providers: [
    BroadcastService,
    ToasterService,
    {
      provide: APP_CONFIG,
      useValue: AppConfig
    },{
      provide: ErrorHandler,
      useClass: ErrorService,
     },
     NavService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
