import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@app/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '@app/core/utility/Utils';
import { GlobalConfig } from '@env/globalconfig';
declare function readyFunction(type): any;

@Component({
    selector: 'mocktest-latest-list',
    templateUrl: './mocktest-latest.component.html'
  })
export class MocktestLatestComponent implements OnInit {
    latestListData: any = [];
    star: any = [{ "no": 1, "fill": "no" }, { "no": 2, "fill": "no" }, { "no": 3, "fill": "no" }, { "no": 4, "fill": "no" }, { "no": 5, "fill": "no" }];
    isInitialLoading: boolean = false;
    isUserLogin: boolean;
    price: string = "all";
    subject: string = "all";
    level: string = "all";
    constructor(
      private _broadcastService: BroadcastService,
      private _apiService: ApiService,
      private router: Router
    ){
      
    }
    ngOnInit() {
      if (localStorage.getItem('accesstoken') == null) {
        this.isUserLogin = false;  
      } else {
        this.isUserLogin = true;
      }
      this._apiService.get(GlobalConfig.Urls.latest_mocktest + "?free=" + this.price + "&subject=" + this.subject + "&level=" + this.level + "&pagin=true&page=1").subscribe((val) => {
        this._broadcastService.broadcastLoader(false);
        this.isInitialLoading = true;
        if (this.router.url.indexOf("/mocktest") > -1) {
          if (val.status == 200) {
            window.scrollTo(0, 0);
            this.latestListData = [];
            val.data.quizes.forEach(element => {
              this.latestListData.push(element);
            });
            setTimeout(function () {
              readyFunction('');
            }, 500);
          } else {  
          }  
        } else {
          // normal pacakges  
          val.data.forEach(element => {
            this.latestListData.push(element);
          });
        }  
      });
    }    
    public checkImageUrl(url) {
      return Utils.checkImageAvailable(url);
    }
    counter(i: number) {
      return new Array(i);
    }    
}