import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import {
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BroadcastService } from '../../core/services/broadcast.service'
import { ToasterModule, ToasterService, BodyOutputType } from "angular2-toaster";


import { AuthService, GlobalService } from '../services';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private toasterService: ToasterService;
  constructor(
    private authService: AuthService,
    private router: Router,
    private _location: Location,
    toasterService: ToasterService,
    private _broadcastService: BroadcastService,
    private globalService: GlobalService
  ) {
    this.toasterService = toasterService;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError(err => {
        let e = {
          message: err.error.message,
          data: err.error.data
        }
        if (err instanceof HttpErrorResponse) {
          let dat = err.error.data || ''
          if (typeof dat === "object") dat = ''

          this._broadcastService.broadcastLoader(false);
          // do error handling here
          if (err.status === 401) {
            //console.log("TCL: HttpErrorInterceptor -> err", err)
            // redirect to login
            //alert(e.message);
            if (!this.globalService.getBuyNowEvent) {
              this.toasterService.clear(); this.toasterService.pop("error", err.error.message, dat)
            }

            this.globalService.setBuyNowEvent(false);

            // if (localStorage.getItem("src") == "web") {
            //this.toasterService.clear();this.toasterService.pop("error", e.message, e.data)
            var path = this._location.path();

            localStorage.clear();
            // debugger;

            if (typeof (path) != "undefined") {
              if (path != '/login')
                sessionStorage.setItem("path", path);
            }
            this.router.navigate(['/login']);
            // }
          } else if (err.status === 400) {
            this.toasterService.clear(); this.toasterService.pop("error", err.error.message, dat)
          } else {
            this.toasterService.clear(); this.toasterService.pop("error", err.error.message, dat)
            // handle other cases
          }
        }
        this._broadcastService.broadcastLoader(false);
        return of(null);
      })
    );
  }
}
