import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class BroadcastService {

  private loader = new Subject<any>();
  loaderSource = this.loader.asObservable();

  private companyView = new Subject<any>();
  companyData = this.companyView.asObservable();

  private userView = new Subject<any>();
  userData = this.companyView.asObservable();


  /*** Loader */
  broadcastLoader(showLoader: boolean) {
    
    this.loader.next(showLoader);
  }

}

